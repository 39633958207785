import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext, authenticatedFetch } from "../../components/AuthenticationContext";
import { useTranslation } from 'react-i18next';
import Styles from './UserAdministration.module.scss';
import { combineClasses } from '../../utils/Utils';
import {
  IDepartmentDto,
  IDepartmentResponseDto,
} from '../../generated/ApiTypes';
import { SelfContext } from '../../components/SelfContext';
import Select from 'react-select';

export const ResponseAdministration = () => {
  const { t } = useTranslation();
  const { companyId, companyName } = useContext(SelfContext);
  const [responses, setResponses] = useState<IDepartmentResponseDto[]>([]);
  const [leafDepartments, setLeafDepartments] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const refresh = useCallback(() => {
    authenticatedFetch(`api/response/company/${companyId}`, {})
      .then((r) => r.json())
      .then(setResponses);
    authenticatedFetch(`api/departments/leafs/${companyId}`, {})
      .then((r) => r.json())
      .then((s: IDepartmentDto[]) => {
        setLeafDepartments(
          s.map((d) => ({ value: d.departmentId, label: d.name }))
        );
      });
  }, []);
  useEffect(refresh, []);
  return (
    <>
      <div className={combineClasses(Styles.AdminPage, Styles.WithBackground)}>
        <div className={Styles.row}>
          <h1>
            {companyName} - {t('Response Administration')}
          </h1>
          <button onClick={refresh} className="btn blue">
            {t('Refresh')}
          </button>
        </div>
        <div className={Styles.UserCardsContainer}>
          {responses?.map((r) => (
            <ResponseCard
              key={r.id}
              response={r}
              leafDepartments={leafDepartments}
              refresh={refresh}
            />
          )) ?? t('Loading forms')}
        </div>
      </div>
    </>
  );
};

const ResponseCard = (props: {
  response: IDepartmentResponseDto;
  leafDepartments: Array<{ value: string; label: string }>;
  refresh: () => void;
}) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const [departmentId, setDepartmentId] = useState<number>(
    props.response.departmentId ?? undefined
  );

  return (
    <div className={Styles.UserCard}>
      <span className={Styles.UserAttribute}>
        {props.response.name ?? ':/'} - {props.response.schemaName ?? ':/'} (
        {props.response.language ?? ':/'})
      </span>
      <label className={Styles.UserAttribute}>
        {t('Department')}
        <Select
          isClearable={false}
          value={props.leafDepartments.find(
            (d) => d.value === departmentId.toString()
          )}
          options={props.leafDepartments}
          placeholder={t('Select')}
          onChange={(selected: any) => setDepartmentId(selected?.value)}
        />
      </label>
      <button
        className="btn blue"
        onClick={() => {
          authContext.authenticatedFetchWithToast(
            `api/response/${props.response.id}/department/${departmentId}`,
            {
              method: 'put',
            }
          ).then(() => {
            props.refresh();
          });
        }}
      >
        {t('Refresh')}
      </button>
      <button
        className="btn blue"
        onClick={() => {
          const inputText = prompt(
            t('Prompt delete yes', {
              type: t('Response').toLowerCase(),
              name: props.response.name,
            })
          )?.trim();
          if (inputText === 'yes') {
            authContext.authenticatedFetchWithToast('/api/response/' + props.response.id, {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
              },
            }).then(() => {
              props.refresh();
            });
          }
        }}
      >
        {t('Delete Response')}
      </button>
    </div>
  );
};
