import React, { useContext, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import styles from './Dashboard.module.scss';
import { calculateEvalue, combineClasses } from '../../utils/Utils';
import { useTranslation } from 'react-i18next';
import { ResponseContext } from '../../components/ResponseContext';
import { AuthContext } from "../../components/AuthenticationContext";
import {
  IActionResponseDto,
  ICategoryResponseDto,
} from '../../generated/ApiTypes';
import { EvalueMeter } from '../../components/EvalueMeter/EvalueMeter';
import { SelfContext } from '../../components/SelfContext';

const Dashboard = () => {
  return (
    <div className={styles.wrapper}>
      <EvaluesOverview />
      <MetadataOverview />
      <ActionOverview />
    </div>
  );
};

const EvaluesOverview = () => {
  const responseContext = useContext(ResponseContext);
  const companyContext = useContext(SelfContext);
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const relevantCategories = [];
  const irrelevantCategories = [];
  const [responseName, setResponseName] = useState<string>(
    responseContext.response?.responseName ?? ''
  );
  const [responseNameEditable, setResponseNameEditable] =
    useState<boolean>(false);
  for (const category of responseContext.response?.categories ?? []) {
    if (category.active) {
      relevantCategories.push({
        ...category,
        evalue: calculateEvalue(
          category.active,
          category.sections,
          category.belowLimit
        ),
      });
    } else irrelevantCategories.push(category);
  }

  console.log(relevantCategories);
  const averageEverdi =
    relevantCategories.reduce((s, c) => s + c.evalue, 0) /
    relevantCategories.length;

  console.log(averageEverdi);

  const saveResponseName = () => {
    if (responseName != responseContext.response?.responseName) {
      authContext.authenticatedFetchWithToast(
        `/api/response/${responseContext.response?.responseId}/name`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            responseName: responseName,
          }),
        }
      );

      const response = responseContext.response;
      if (response) {
        responseContext.refresh({ ...response, responseName: responseName });
        companyContext.updateDepartmentResponseName(
          response.responseId,
          responseName
        );
      }
    }
    setResponseNameEditable(false);
  };

  return (
    <div className={combineClasses(styles.evalues, styles.box)}>
      <div className={combineClasses(styles.row)}>
        <div>
          <h1>{responseContext.response?.department}</h1>
          <input
            type="text"
            name="responseName"
            value={responseName}
            className={responseNameEditable ? styles.active : ''}
            onClick={() => {
              if (!responseNameEditable) setResponseNameEditable(true);
            }}
            onChange={(e) => {
              if (e.target.value != '') setResponseName(e.target.value);
            }}
            onBlur={() => saveResponseName()}
            disabled={!responseContext.response?.active}
          />
        </div>
        <div className={styles.total}>
          <h1>
            {t('Total value')}: {averageEverdi.toFixed(1)}
          </h1>
          <div className={styles.meter}>
            <EvalueMeter value={averageEverdi} hideText={true} />
          </div>
        </div>
      </div>
      <div className={styles.categories}>
        {relevantCategories.map((category) => (
          <CategoryCard key={category.categoryResponseId} {...category} />
        ))}

        <div className={styles.separator}>{t('No exposure')}</div>

        {irrelevantCategories.map((category) => (
          <CategoryCard key={category.categoryResponseId} {...category} />
        ))}
      </div>
    </div>
  );
};

interface CategoryCardProps extends ICategoryResponseDto {
  evalue?: number;
}

const CategoryCard = (props: CategoryCardProps) => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const isStarted = props.sections.some((s) =>
    s.questions.some((q) => q.responseValue !== 0)
  );
  const getEvalueStatusText = () => {
    if (!isStarted || !props.evalue) return '';
    if (props.evalue >= 60) return t('Must be improved');
    if (props.evalue >= 25) return t('Should be improved');
    return t('Acceptable');
  };

  return (
    <Link
      to={`${url}/category/${props.categoryResponseId}`}
      className={combineClasses(styles.card, styles.hover)}
    >
      <h2 className={styles.title}>{props.title}</h2>
      {props.active ? (
        <>
          {!isStarted || !props.evalue ? (
            <div className={styles.button}>{t('Start completion of form')}</div>
          ) : (
            <>
              <div className={styles.categoryMeter}>
                <EvalueMeter value={props.evalue} hideText={true} />
              </div>
            </>
          )}

          <div className={styles.footer}>
            <p>
              {t('Value')} {props.evalue}
            </p>
            <p>
              {t('Action')}:{' '}
              {props.sections.reduce(
                (sum, section) => sum + section.actions.length,
                0
              )}
            </p>
            <p>
              <b>status:</b> {getEvalueStatusText()}
            </p>
          </div>
        </>
      ) : (
        <div className={combineClasses(styles.button, styles.inactive)}>
          {t('Edit')}
        </div>
      )}
    </Link>
  );
};

const MetadataOverview = () => {
  const responseContext = useContext(ResponseContext);
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  return (
    <div className={combineClasses(styles.employees, styles.box)}>
      <div className={combineClasses(styles.row, styles.participants)}>
        <h1>{t('Participants')}</h1>
        {isEditing ? (
          <>
            <button
              onClick={() => {
                authContext.authenticatedFetchWithToast(
                  `/api/response/${responseContext.response?.responseId}/notes`,
                  {
                    method: 'PUT',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      notes: responseContext.response?.notes,
                    }),
                  }
                );
                setIsEditing(false);
              }}
              className="btn blue"
            >
              {t('Save')}
            </button>
            <textarea
              className={styles.participantsText}
              value={responseContext.response?.notes}
              onChange={(e) => {
                const response = responseContext.response;
                const notes = e.target.value;
                if (response) responseContext.refresh({ ...response, notes });
              }}
            />
          </>
        ) : (
          <>
            <button
              onClick={() => setIsEditing(true)}
              className="btn blue"
              disabled={!responseContext.response?.active}
            >
              {t('Edit')}
            </button>
            <span className={styles.participantsText}>
              {responseContext.response?.notes}
            </span>
          </>
        )}
      </div>
    </div>
  );
};

const ActionOverview = () => {
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(0);
  const [editedActions, setEditedActions] = useState<Set<IActionResponseDto>>(
    new Set([])
  );
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const responseContext = useContext(ResponseContext);

  const handleEditAndSave = () => {
    if (editedActions.size > 0) {
      authContext.authenticatedFetchWithToast('/api/Answers/actions', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(Array.from(editedActions)),
      });
      setEditedActions(new Set());
    }
  };

  const handleFulfilledChange = (event: any, action: IActionResponseDto) => {
    action.fulfilled = event.target.checked;
    const editedSet = new Set(editedActions);

    if (editedActions.has(action)) {
      editedSet.delete(action);
    } else {
      editedSet.add(action);
    }

    setEditedActions(editedSet);
  };

  const handleCategoryChange = (event: any) => {
    setSelectedCategoryId(parseInt(event.target.value));
  };

  return (
    <div className={combineClasses(styles.actions, styles.box)}>
      <div className={styles.row}>
        <h1>{t('Action plan')}</h1>
        <h1>
          {t('No of actions')}:{' '}
          {responseContext.response?.categories
            .filter((c) => c.active)
            .reduce(
              (sumCategory: number, category) =>
                sumCategory +
                category.sections.reduce(
                  (sumSection: number, section) =>
                    sumSection + section.actions.length,
                  0
                ),
              0
            )}
        </h1>
      </div>
      <div className={styles.row}>
        <select
          onChange={(e) => handleCategoryChange(e)}
          className={styles.select}
        >
          <option value={0}>{t('All categories')}</option>
          {responseContext.response?.categories.map((category) => (
            <option
              value={category.categoryResponseId}
              key={category.categoryResponseId}
            >
              {category.title}
            </option>
          ))}
        </select>
        <button
          onClick={() => handleEditAndSave()}
          className="btn blue"
          disabled={!responseContext.response?.active || editedActions.size < 1}
        >
          {t('Save')}
        </button>
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            {selectedCategoryId == 0 && (
              <th>
                <span>{t('Category')}</span>
              </th>
            )}
            <th>
              <span>{t('Section')}</span>
            </th>
            <th>
              <span>{t('Action')}</span>
            </th>
            <th>
              <span>{t('Date')}</span>
            </th>
            <th>
              <span>{t('Completed')}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {responseContext.response?.categories.map((category) => {
            return (
              (selectedCategoryId == 0 ||
                category.categoryResponseId == selectedCategoryId) &&
              category.active &&
              category.sections.map((section) =>
                section.actions.map((action) => (
                  <tr key={action.actionId}>
                    {selectedCategoryId == 0 && (
                      <td className={styles.fitContent}>{category.title}</td>
                    )}
                    <td className={styles.fitContent}>{section.title}</td>
                    <td>{action.text}</td>
                    <td className={styles.fitContent}>
                      {new Date(action.date).toLocaleDateString(
                        t('DateLocale')
                      )}
                    </td>
                    <td className={styles.fitContent}>
                      <input
                        type="checkbox"
                        defaultChecked={action.fulfilled}
                        onChange={(e) => handleFulfilledChange(e, action)}
                        id={`checkbox_${action.actionId}`}
                        className={styles.checkbox}
                        disabled={!responseContext.response?.active}
                      />
                    </td>
                  </tr>
                ))
              )
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Dashboard;
