import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  AccountInfo,
  AuthenticationResult,
  Configuration,
  PopupRequest,
  PublicClientApplication,
} from '@azure/msal-browser';
import { ToastContext } from './Toaster/Toaster';
import { useTranslation } from 'react-i18next';

export const AuthProvider: React.FC = ({ children }) => {
  const [authContextProviderProps, setAuthContextProviderProps] =
    useState<AuthContextProviderProps>();
  useEffect(() => {
    fetch('/api/config')
      .then((r) => r.json())
      .then(init)
      .then((initResults) => {
        setAuthContextProviderProps({
          ...initResults,
          initialAccount: initResults.msalInstance.getAllAccounts()[0],
        });
      });
  }, []);
  console.log(authContextProviderProps);
  return authContextProviderProps ? (
    <AuthContextProvider {...authContextProviderProps}>
      {children}
    </AuthContextProvider>
  ) : (
    <>{children}</>
  );
};

interface AuthContextProviderProps {
  msalInstance: PublicClientApplication;
  loginRequest: PopupRequest;
  resetRequest: PopupRequest;
  initialAccount?: AccountInfo;
}

const AuthContextProvider: React.FC<AuthContextProviderProps> = (props) => {
  const { t } = useTranslation();
  const toastContext = useContext(ToastContext);
  const [account, setAccount] = useState<AccountInfo | undefined | null>(
    props.initialAccount,
  );
  const afterLogin = useCallback((tokenResponse: AuthenticationResult) => {
    setAccount(tokenResponse.account);
    return tokenResponse;
  }, []);
  const login = useCallback(
    () => props.msalInstance.loginPopup(props.loginRequest).then(afterLogin),
    [],
  );
  const logout = useCallback(
    () =>
      props.msalInstance.logout().then(() => {
        setAccount(undefined);
      }),
    [],
  );
  const reset = useCallback(
    () =>
      props.msalInstance
        .loginPopup(props.resetRequest)
        .then(afterLogin)
        .catch(console.log),
    [],
  );
  const authenticatedFetchWithToast = useCallback<AuthenticatedFetch>((input, init, requestName) => {
    const requestNameGuess = typeof input === 'string' ? (input.split('/').filter(s => s.length > 0)[1] ?? '') : '';
    toastContext.addToast({ type: 'waiting', message: `${t('Saving')} ${t(requestName ?? requestNameGuess)}` });
    return authenticatedFetch(input, init).then((res) => {
      if (res.ok) {
        toastContext.addToast({ type: 'success', message: `${t('Saved')} ${t(requestName ?? requestNameGuess)}` });
      } else {
        toastContext.addToast({ type: 'error', message: `${t('Error when saving')} ${t(requestName ?? requestNameGuess)}` });
      }
      return res;
    }).catch((e) => {
      toastContext.addToast({ type: 'error', message: `${t('Error when saving')} ${t(requestName ?? requestNameGuess)}` });
      throw e;
    });
  }, [toastContext.addToast]);
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!account,
        login,
        logout,
        reset,
        account,
        authenticatedFetchWithToast,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

let getAccessToken =
  async function uninitializedGetAccessToken(): Promise<string> {
    throw new Error(
      'Attempted to get access token before authentication was initialized',
    );
  };

type AuthenticatedFetch = (input: RequestInfo, init?: RequestInit, requestName?: string) => Promise<Response>
export const authenticatedFetch: AuthenticatedFetch = async (input, init) => {
  const accessToken = await getAccessToken();
  return fetch(input, {
    ...init,
    headers: { ...init?.headers, Authorization: `Bearer ${accessToken}` },
  });
};

const init = ({
                instance,
                clientId,
                domain,
              }: {
  instance: string;
  clientId: string;
  domain: string;
}) => {
  const msalConfig: Configuration = {
    auth: {
      authority: `${instance}/tfp/${domain}/b2c_1_SignIn`,
      clientId,
      redirectUri: window.location.origin,
      knownAuthorities: [instance],
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: true,
    },
  };

  const msalInstance = new PublicClientApplication(msalConfig);

  const scopes = [
    'openid',
    'offline_access',
    `https://${domain}/${clientId}/EVerdier.Read`,
    `https://${domain}/${clientId}/EVerdier.Write`,
  ];
  const loginRequest: PopupRequest = { scopes };
  const resetRequest: PopupRequest = {
    authority: `${instance}/tfp/${domain}/b2c_1_reset`,
    scopes,
  };

  getAccessToken = async () => {
    const request = {
      account: msalInstance.getAllAccounts()[0],
      scopes,
    };
    const tokenResponse = await msalInstance?.acquireTokenSilent(request);
    // .catch(() => msalInstance.acquireTokenPopup(request));
    if (tokenResponse.accessToken === undefined)
      throw Error('Unable to retrieve token');
    return tokenResponse.accessToken;
  };

  return { msalInstance, loginRequest, resetRequest };
};

interface AuthContextContent {
  isAuthenticated: boolean;
  login: () => Promise<AuthenticationResult | void>;
  logout: () => Promise<void>;
  reset: () => Promise<AuthenticationResult | void>;
  account?: AccountInfo | null;
  authenticatedFetchWithToast: AuthenticatedFetch;
}

export const AuthContext = React.createContext<AuthContextContent>({
  isAuthenticated: false,
} as AuthContextContent);
