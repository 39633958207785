import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext, authenticatedFetch } from "../../components/AuthenticationContext";
import { useTranslation } from 'react-i18next';
import { SelfContext } from '../../components/SelfContext';
import Select from 'react-select';
import Styles from './UserAdministration.module.scss';
import { combineClasses } from '../../utils/Utils';
import { IDepartmentDto } from '../../generated/ApiTypes';
import { Departments, Roles } from '../../Constants';

interface ISubDepartments {
  id: string;
  name: string;
  departments: IDepartmentDto[];
  branchDepartments: Array<{ value: string; label: string }>;
}

export const DepartmentAdministrationContainer = () => {
  const { t } = useTranslation();
  const { companyId, companyName, role, departments } = useContext(SelfContext);
  const [subDepartments, setSubDepartments] = useState<ISubDepartments[]>([]);
  const refresh = useCallback(() => {
    let subDep: ISubDepartments[] = [];
    if (role == Roles.OWNER || role == Roles.ADMIN) {
      Promise.all([
        authenticatedFetch(`api/departments/tree/${companyId}`, {}),
        authenticatedFetch(`api/departments/branches/${companyId}`, {}),
      ])
        .then((responses) =>
          Promise.all(responses.map((response) => response.json()))
        )
        .then((data) => {
          setSubDepartments([
            {
              id: companyId,
              name: companyName,
              departments: data[0],
              branchDepartments: data[1].map((d: IDepartmentDto) => ({
                value: d.departmentId,
                label: d.name,
              })),
            },
          ]);
        })
        .catch((err) => console.log(err));
    } else {
      departments
        .filter((d) => d.role == Roles.OWNER)
        .forEach((d) => {
          Promise.all([
            authenticatedFetch(
              `api/departments/tree/${companyId}/${d.departmentId}`,
              {}
            ),
            authenticatedFetch(
              `api/departments/branches/${companyId}/${d.departmentId}`,
              {}
            ),
          ])
            .then((responses) =>
              Promise.all(responses.map((response) => response.json()))
            )
            .then((data) => {
              subDep = subDep.concat({
                id: d.departmentId,
                name: d.departmentName,
                departments: data[0],
                branchDepartments: data[1].map((d: IDepartmentDto) => ({
                  value: d.departmentId,
                  label: d.name,
                })),
              });
              setSubDepartments(subDep);
            })
            .catch((err) => console.log(err));
        });
    }
  }, []);
  useEffect(refresh, []);
  return (
    <>
      <div className={combineClasses(Styles.AdminPage, Styles.WithBackground)}>
        {subDepartments.map(
          (d) =>
            !subDepartments
              .filter((od) => !(od.id == d.id))
              .flatMap((od) => od.branchDepartments.map((bd) => bd.value))
              .some((dep) => dep == d.id) && (
              <DepartmentAdministration
                key={d.id}
                companyId={companyId}
                name={d.name}
                departments={d.departments}
                branchDepartments={d.branchDepartments}
                refresh={refresh}
              />
            )
        ) ?? t('Loading departments')}
      </div>
    </>
  );
};

const DepartmentAdministration = (props: {
  companyId: string;
  name: string;
  departments?: IDepartmentDto[];
  branchDepartments?: Array<{ value: string; label: string }>;
  refresh: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <div className={Styles.marginBottom}>
      <div className={Styles.row}>
        <h1>
          {props.name} - {t('Department Administration')}
        </h1>
        <button
          onClick={props.refresh}
          className={combineClasses('btn blue', Styles.deleteButton)}
        >
          {t('Refresh')}
        </button>
      </div>
      <div className={Styles.UserCardsContainer}>
        {props.departments?.map((d) => (
          <DepartmentCard
            key={d.departmentId}
            department={d}
            companyId={props.companyId}
            refresh={props.refresh}
          />
        )) ?? t('Loading departments')}
        <NewDepartmentCard
          companyId={props.companyId}
          name={props.name}
          branchDepartments={props.branchDepartments}
          refresh={props.refresh}
        />
      </div>
    </div>
  );
};

const useString = (masterString?: string) => {
  const state = useState(masterString);
  useEffect(() => state[1](masterString), [masterString]);
  return state;
};

const DepartmentCard = (props: {
  department: IDepartmentDto;
  companyId: string;
  refresh?: () => void;
}) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);

  return (
    <>
      <div className={Styles.UserCard}>
        <label className={Styles.UserAttribute}>
          {t('Name')}
          <span className={Styles.UserAttribute}>
            {props.department.name ?? ''}
          </span>
        </label>
        <label className={Styles.UserAttribute}>
          <span className={Styles.UserAttribute}>
            {t(props.department.type)}
          </span>
        </label>
        <button // Only works if there are no subDepartments
          disabled={Array.isArray(props.department.subDepartments)}
          className={combineClasses('btn blue', Styles.deleteButton)}
          onClick={async () => {
            if (
              confirm(
                t('Confirm delete', {
                  type: t('Department').toLowerCase(),
                  name: props.department.name,
                })
              )
            ) {
              await authContext.authenticatedFetchWithToast(
                `api/departments/${props.department.departmentId}`,
                {
                  method: 'delete',
                }
              );
              props.refresh?.();
            }
          }}
        >
          {t('Delete department')}
        </button>
      </div>
      <div className={Styles.SubDepartment}>
        {Array.isArray(props.department.subDepartments)
          ? props.department.subDepartments.map((d) => (
              <DepartmentCard
                key={d.departmentId}
                department={d}
                companyId={props.companyId}
                refresh={props.refresh}
              />
            ))
          : null}
      </div>
    </>
  );
};

const NewDepartmentCard = (props: {
  companyId: string;
  name: string;
  branchDepartments?: Array<{ value: string; label: string }>;
  refresh?: () => void;
}) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const [name, setName] = useString('');
  const [parentDepartmentId, setParentDepartmentId] = useString(
    props.branchDepartments?.find((d) => d.label === props.name)?.value ?? ''
  );
  const [type, setType] = useString(Departments.LEAF);
  const typeOptions = [
    { value: Departments.BRANCH, label: t(Departments.BRANCH) },
    { value: Departments.LEAF, label: t(Departments.LEAF) },
  ];

  return (
    <div className={Styles.UserCard}>
      <label className={Styles.UserAttribute}>
        {t('Name')}
        <input
          className={Styles.UserAttribute}
          placeholder={t('Name')}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </label>
      <label className={Styles.UserAttribute}>
        {t('Parent department')}
        <Select
          isClearable={
            !props.branchDepartments?.some((d) => d.label === props.name)
          }
          value={props.branchDepartments?.find(
            (d) => d.value === parentDepartmentId
          )}
          options={props.branchDepartments ?? []}
          placeholder={t('Select')}
          onChange={(selected: any) => setParentDepartmentId(selected?.value)}
        />
      </label>
      <label className={Styles.UserAttribute}>
        {t('Department type')}
        <Select
          isClearable={false}
          value={typeOptions.find((d) => d.value === type)}
          options={typeOptions}
          placeholder={t('Select')}
          onChange={(selected: any) => setType(selected?.value)}
        />
      </label>
      <button
        className="btn blue"
        disabled={name == ''}
        onClick={async () => {
          await authContext.authenticatedFetchWithToast('api/departments', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              companyId: props.companyId,
              name,
              parentDepartmentId,
              type,
            }),
          });
          props.refresh?.();
          setName('');
          setParentDepartmentId('');
          setType(Departments.LEAF);
        }}
      >
        {t('Add department')}
      </button>
    </div>
  );
};
