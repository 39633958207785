import React, { useContext } from 'react';
import styles from './LandingPage.module.scss';
import { AuthContext } from '../../components/AuthenticationContext';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();
  return (
    <div className={styles.landingPage}>
      <Link to="/" className={styles.logo}>
        <img src="logo.png" alt="Logo" />
      </Link>
      <div className={styles.content}>
        <h1>{t('LandingTitle')}&trade;</h1>
        <p>{t('LandingIngress')}</p>
        <div className={styles.buttonGroup}>
          <button onClick={authContext.login}>{t('Log in')}</button>
          <button onClick={authContext.reset}>{t('Get access')}</button>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
