import React, { useState, useContext, useEffect, useRef } from 'react';
import { Actions } from '../../Constants';
import { AuthContext } from "../../components/AuthenticationContext";
import { useRouteMatch } from 'react-router-dom';
import styles from './Section.module.scss';
import { useTranslation } from 'react-i18next';
import { ResponseContext } from '../../components/ResponseContext';
import {
  IActionResponseDto,
  IQuestionResponseDto,
  ISectionResponseDto,
} from '../../generated/ApiTypes';

interface SectionProps {
  categoryResponseId: number;
}

function useSaveOnExit(isChanged: boolean, save: () => void) {
  const refreshRef = useRef({ isChanged, save });
  refreshRef.current = { isChanged, save };
  useEffect(
    () => () => {
      const { isChanged, save } = refreshRef.current;
      //console.log('asdf', isChanged);
      if (isChanged) save();
    },
    [refreshRef]
  );
}

const Section: React.FC<SectionProps> = ({ categoryResponseId }) => {
  const responseContext = useContext(ResponseContext);
  const [sectionPrev, setSectionPrev] = useState<ISectionResponseDto>();
  const [newAction, setNewAction] = useState<boolean>(false);
  const { id }: any = useRouteMatch().params;
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);

  const section = responseContext.response?.categories
    .find((category) => category.categoryResponseId == categoryResponseId)
    ?.sections.find((section) => section.sectionResponseId == id);

  if (!section) {
    return <h1>Error</h1>;
  }

  const sectionRef = useRef(section);
  if (sectionRef.current.sectionId === section.sectionId) {
    sectionRef.current = section;
  }
  useEffect(() => {
    if (
      sectionPrev &&
      JSON.stringify(sectionRef.current) !== JSON.stringify(sectionPrev)
    ) {
      saveSection(sectionRef.current);
    }
    setSectionPrev(JSON.parse(JSON.stringify(section)));
    sectionRef.current = section;
  }, [id]);

  const handleSectionActiveChange = (event: any) => {
    const sectionUpdate = { ...section, active: !event.target.checked };
    responseContext.refreshSection(categoryResponseId, sectionUpdate);
  };

  const handleNoteChange = (event: any) => {
    const sectionUpdate = { ...section, note: event.target.value };
    responseContext.refreshSection(categoryResponseId, sectionUpdate);
  };

  const handleCheckboxChange = (event: any, question: IQuestionResponseDto) => {
    const questionUpdate = {
      ...question,
      responseValue: event.target.checked ? 1 : 0,
    };
    responseContext.refreshQuestion(
      categoryResponseId,
      section.sectionResponseId,
      questionUpdate
    );
  };

  const handleActionCreate = (event: any) => {
    if (event.target.value == '') return;

    const actionResponse: IActionResponseDto = {
      actionId: -1,
      sectionId: section.sectionId,
      text: event.target.value,
      fulfilled: false,
      date: new Date(),
    };

    responseContext.refreshAction(
      categoryResponseId,
      section.sectionResponseId,
      -1,
      Actions.CREATE,
      actionResponse
    );
  };

  const handleActionChange = (
    event: any,
    actionResponse: IActionResponseDto,
    index: number
  ) => {
    const actionUpdate = {
      ...actionResponse,
      text: event.target.value,
    };
    responseContext.refreshAction(
      categoryResponseId,
      section.sectionResponseId,
      index,
      Actions.UPDATE,
      actionUpdate
    );
  };

  const handleActionDelete = (
    actionResponse: IActionResponseDto,
    index: number
  ) => {
    responseContext.refreshAction(
      categoryResponseId,
      section.sectionResponseId,
      index,
      Actions.DELETE,
      actionResponse
    );
  };

  const saveSection = (sectionToSave: ISectionResponseDto) =>
    authContext.authenticatedFetchWithToast('/api/Answers/section', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(sectionToSave),
    })
      .then((response) => response.json())
      .then((updatedSection) => {
        responseContext.refreshSection(categoryResponseId, updatedSection);
        return updatedSection;
      })
      .catch((error) => {
        console.log(error);
      });

  const saveSectionAndUpdatePrev = () => {
    saveSection(section).then((updatedSection) =>
      setSectionPrev(JSON.parse(JSON.stringify(updatedSection)))
    );
  };

  const isChanged =
    sectionPrev && sectionPrev.sectionId === section.sectionId
      ? JSON.stringify(section) !== JSON.stringify(sectionPrev)
      : false;
  const singleQuestionAnswered =
    (section.questions.find(
      (x) => x.questionId == section.notApplicableQuestionId
    )?.responseValue ?? 0) > 0;
  const nonSingleQuestionsAnswered = section.questions
    .filter((x) => x.questionId != section.notApplicableQuestionId)
    .some((x) => x.responseValue !== 0);

  useSaveOnExit(isChanged, () => saveSection(section));

  return (
    <>
      <div className={styles.heading}>
        <h1>{section.title}</h1>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.description}>
          <h1>{t('Description')}</h1>
          {section.description}
        </div>

        <div className={styles.checkboxes}>
          <h1>{t('Choose for company')}</h1>
          <ul>
            {section.questions.map((question) => (
              <li
                className={styles.checkboxContainer}
                key={question.questionId}
              >
                <input
                  type="checkbox"
                  defaultChecked={question.responseValue == 1}
                  onChange={(e) => handleCheckboxChange(e, question)}
                  id={`checkbox_${question.questionId}`}
                  className={styles.checkbox}
                  disabled={
                    !responseContext.response?.active ||
                    (section.notApplicableQuestionId != question.questionId &&
                      singleQuestionAnswered) ||
                    (section.notApplicableQuestionId == question.questionId &&
                      nonSingleQuestionsAnswered)
                  }
                />
                <label htmlFor={`checkbox_${question.questionId}`}>
                  {question.text}
                </label>
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.notes}>
          <h1>{t('Notes')}</h1>
          <textarea
            placeholder={t('Type here')}
            value={section.note}
            onChange={(e) => {
              handleNoteChange(e);
            }}
            disabled={!responseContext.response?.active}
          />
        </div>

        <div className={styles.actions}>
          <div className={styles.box}>
            <h1>{t('Action')}</h1>

            <ol className={styles.list}>
              {section.actions.map((actionResponse, index) => (
                <li key={index}>
                  <textarea
                    rows={4}
                    cols={50}
                    className={styles.input}
                    placeholder={t('Type here')}
                    value={actionResponse.text}
                    onChange={(e) => {
                      handleActionChange(e, actionResponse, index);
                    }}
                    disabled={!responseContext.response?.active}
                  />
                  <button
                    className={styles.deleteButton}
                    onClick={() => handleActionDelete(actionResponse, index)}
                    disabled={!responseContext.response?.active}
                  >
                    {t('Delete')}
                  </button>
                </li>
              ))}

              {section.actions.length < 1 && (
                <li>
                  <textarea
                    rows={1}
                    cols={50}
                    className={styles.input}
                    placeholder={t('Type here')}
                    onBlur={(e) => handleActionCreate(e)}
                    disabled={!responseContext.response?.active}
                  />
                </li>
              )}
              {section.actions.length < 2 && (
                <li>
                  <textarea
                    rows={1}
                    cols={50}
                    className={styles.input}
                    placeholder={t('Type here')}
                    onBlur={(e) => handleActionCreate(e)}
                    disabled={!responseContext.response?.active}
                  />
                </li>
              )}
              {newAction && section.actions.length > 1 && (
                <li>
                  <textarea
                    rows={4}
                    cols={50}
                    className={styles.input}
                    placeholder={t('Type here')}
                    onBlur={(e) => {
                      handleActionCreate(e);
                      setNewAction(false);
                    }}
                    disabled={!responseContext.response?.active}
                  />
                </li>
              )}
            </ol>
            <button
              className={styles.addButton}
              disabled={!responseContext.response?.active}
              onClick={() => {
                if (section.actions.length > 1) setNewAction(true);
              }}
            >
              {t('Add')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section;
