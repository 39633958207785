import React, { useContext, useState } from "react";
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
import { combineClasses } from '../../utils/Utils';
import Styles from './UserAdministration.module.scss';
import { AuthContext } from "../../components/AuthenticationContext";

const fromXlsxToJson = (xlsxFile: File, save: (jsonData: any) => void) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    const binaryString = event?.target?.result;
    const workBook = XLSX.read(binaryString, { type: 'binary' });
    const workSheet = workBook.Sheets[workBook.SheetNames[0]];
    let jsonData = XLSX.utils.sheet_to_json(workSheet, {
      header: ['code', 'description'],
    });
    jsonData = JSON.parse(JSON.stringify(jsonData).replace(/"\s+|\s+"/g, '"'));
    save(jsonData);
  };
  reader.readAsBinaryString(xlsxFile);
};

export const NaceAdministration = () => {
  const [inputFile, setInputFile] = useState(null);
  const [feedback, setFeedback] = useState('');
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);

  const [sectionVisible, setSectionVisible] = useState(false);

  const handleFileInput = (event: any) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (!file.name.endsWith('.xlsx')) {
      setFeedback(t('Invalid file format'));
    } else {
      setFeedback('');
      setInputFile(file);
    }
  };

  const saveNaces = (naces: any) => {
    authContext.authenticatedFetchWithToast('api/companies/nace', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ naces }),
    })
      .then((response) => response.json())
      .then((data: number) => {
        if (data === 0) {
          setFeedback(t('Nace add fail'));
        } else {
          setFeedback(
            t('Nace add success').replace('{numberOfChanges}', data.toString())
          );
        }
      });
  };

  const deleteNaces = () => {
    if (confirm(t('Confirm delete nace'))) {
      authContext.authenticatedFetchWithToast('api/companies/nace', {
        method: 'delete',
      });
    }
  };

  const saveNaceCodes = () => {
    const xlsxFile = inputFile;
    if (!xlsxFile) {
      console.log('No value');
      return;
    }

    fromXlsxToJson(xlsxFile, saveNaces);
  };

  if (!sectionVisible) {
    return (
      <div className={combineClasses(Styles.naceHidden, Styles.nace)}>
        <h1 onClick={() => setSectionVisible(true)}>
          {t('Nace code administration')}
        </h1>
      </div>
    );
  }

  return (
    <div
      className={combineClasses(
        Styles.AdminPage,
        Styles.WithBackground,
        Styles.nace
      )}
    >
      <div>
        <h1 onClick={() => setSectionVisible((visible) => !visible)}>
          {t('Nace code administration')}
        </h1>
        <div>{t('Nace file description')}</div>

        <div className={Styles.naceFileUpload}>
          <input
            className={Styles.Input}
            type="file"
            onChange={handleFileInput}
          />
          <button
            className="btn blue"
            onClick={saveNaceCodes}
            disabled={!inputFile}
          >
            {t('Save')}
          </button>
          <button className="btn blue" onClick={deleteNaces}>
            {t('Delete')}
          </button>
        </div>

        <div>{feedback}</div>
      </div>
    </div>
  );
};
