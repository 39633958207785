import { ISectionResponseDto } from '../generated/ApiTypes';

export const combineClasses = (...classNames: string[]) => classNames.join(' ');

export const calculateEvalue = (
  active: boolean,
  sections: ISectionResponseDto[],
  belowLimit: boolean
) => {
  if (!active) {
    return 0;
  } else {
    return Math.max(
      10,
      Math.min(
        100,
        (belowLimit ? 50 : 100) +
          sections
            .flatMap((s) => s.questions)
            .reduce((s, q) => s + q.responseValue * q.impact, 0)
      )
    );
  }
};
