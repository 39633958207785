import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
// import registerServiceWorker from './registerServiceWorker';
import { AuthProvider } from "./components/AuthenticationContext";
import "./i18n";
import { ToastProvider } from "./components/Toaster/Toaster";
import Spinner from "./components/Spinner/Spinner";

const baseUrl =
  document.getElementsByTagName("base")[0].getAttribute("href") ?? "";
const rootElement = document.getElementById("root");

ReactDOM.render(
  <Suspense fallback={<Spinner />}>
    <ToastProvider>
      <BrowserRouter basename={baseUrl}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </BrowserRouter>
    </ToastProvider>
  </Suspense>
  ,
  rootElement
);

// registerServiceWorker();
