import React, { useContext } from 'react';
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  Link,
} from 'react-router-dom';
import styles from './Category.module.scss';
import { calculateEvalue, combineClasses } from '../../utils/Utils';
import CategoryExposure from '../CategoryExposure/CategoryExposure';
import Section from '../Section/Section';
import { ResponseContext } from '../../components/ResponseContext';
import { useTranslation } from 'react-i18next';
import { EvalueMeter } from '../../components/EvalueMeter/EvalueMeter';

const Category = () => {
  const responseContext = useContext(ResponseContext);
  const { t } = useTranslation();
  const { id }: any = useRouteMatch().params;
  const { url } = useRouteMatch();
  const category = responseContext.response?.categories.find(
    (category) => category.categoryResponseId == id
  );

  if (!category) {
    return <h1>Error</h1>;
  }

  const evalue = calculateEvalue(
    category.active,
    category.sections,
    category.belowLimit
  );

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.heading}>
        <Link
          to={`/response/${responseContext.response?.responseId}`}
          className={styles.backButton}
        >
          <i className={combineClasses('fas fa-caret-left', styles.icon)} />
          {t('Back').toUpperCase()}
        </Link>

        <h1>{category.title}</h1>

        <div className={styles.progress}>
          <h1>{t('E-Score')}</h1>
          <div className={styles.meterbox}>
            <EvalueMeter value={evalue} />
          </div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <ul className={styles.menu}>
          <li key="0">
            <NavLink to={`${url}/section/0`} activeClassName={styles.active}>
              <div className={combineClasses(styles.circle, styles.modified)} />
              {t('Exposure').toUpperCase()}
              <i
                className={combineClasses('fas fa-caret-right', styles.icon)}
              />
            </NavLink>
          </li>

          {category.sections.map((section) => {
            const modified = section.questions.some(
              (q) => q.responseValue !== 0
            );

            return (
              <li key={section.sectionResponseId}>
                <NavLink
                  to={`${url}/section/${section.sectionResponseId}`}
                  activeClassName={styles.active}
                  className={!category.active ? styles.disabled : ''}
                >
                  <div
                    className={combineClasses(
                      styles.circle,
                      modified ? styles.modified : ''
                    )}
                  />
                  {section.title}
                  <i
                    className={combineClasses(
                      'fas fa-caret-right',
                      styles.icon
                    )}
                  />
                </NavLink>
              </li>
            );
          })}
        </ul>
        <div className={styles.content}>
          <Switch>
            <Route path={`${url}/section/0`}>
              <CategoryExposure categoryResponseId={id} />
            </Route>
            <Route path={`${url}/section/:id`}>
              <Section categoryResponseId={id} />
            </Route>
            <Route path="/">
              <Redirect to={`${url}/section/0`} />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Category;
