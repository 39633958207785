import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Actions } from '../Constants';
import {
  IDepartmentResponseDto,
  ISchemaDto,
  IUserDepartmentDto,
} from '../generated/ApiTypes';
import { AuthContext, authenticatedFetch } from './AuthenticationContext';

export const SelfProvider: React.FC = ({ children }) => {
  const authContext = useContext(AuthContext);
  const [companyId, setCompanyId] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [departments, setDepartments] = useState<IUserDepartmentDto[]>([]);
  const [departmentResponses, setDepartmentResponses] = useState<
    IDepartmentResponseDto[]
  >([]);
  const [role, setRole] = useState('');
  const [schemas, setSchemas] = useState<ISchemaDto[]>([]);
  const refresh = useCallback(() => {
    authenticatedFetch('/api/self', {})
      .then((r) => r.json())
      .then((s) => {
        setCompanyId(s.company?.companyId);
        setCompanyName(s.company?.name);
        setDepartmentResponses(s.departmentResponses);
        setDepartments(s.departments);
        setRole(s.role);
        setSchemas(s.schemas);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const updateSchema = (newSchema: ISchemaDto, action: string) => {
    const index = schemas.findIndex((x) => x.schemaId == newSchema.schemaId);
    if (index == -1 && (action == Actions.UPDATE || action == Actions.DELETE)) {
      return;
    }

    let newSchemas = schemas;

    if (action == Actions.UPDATE) {
      newSchemas[index] = newSchema;
    } else if (action == Actions.CREATE) {
      newSchemas = [...{ schemas: newSchemas }.schemas, newSchema];
    } else if (action == Actions.DELETE) {
      newSchemas.splice(index, 1);
    }
    setSchemas(newSchemas);
  };

  const updateDepartmentResponseName = (
    responseId: number,
    responseName: string
  ) => {
    const index = departmentResponses.findIndex((x) => x.id == responseId);
    if (index == -1) {
      return;
    }
    const newResponses = departmentResponses;
    newResponses[index].name = responseName;
    setDepartmentResponses(newResponses);
  };

  const updateDepartmentResponseActive = (
    responseId: number,
    active: boolean
  ) => {
    const index = departmentResponses.findIndex((x) => x.id == responseId);
    if (index == -1) {
      return;
    }
    const newResponses = departmentResponses;
    newResponses[index].active = active;
    setDepartmentResponses(newResponses);
  };

  useEffect(() => {
    if (authContext.isAuthenticated) refresh();
  }, [authContext.isAuthenticated]);
  return (
    <SelfContext.Provider
      value={{
        companyId,
        companyName,
        departments,
        departmentResponses,
        updateDepartmentResponseName: updateDepartmentResponseName,
        updateDepartmentResponseActive: updateDepartmentResponseActive,
        refresh,
        updateSchema,
        role,
        schemas,
      }}
    >
      {children}
    </SelfContext.Provider>
  );
};

export const SelfContext = React.createContext<{
  companyId: string;
  companyName: string;
  departments: IUserDepartmentDto[];
  departmentResponses: IDepartmentResponseDto[];
  updateDepartmentResponseName: (
    responseId: number,
    responseName: string
  ) => void;
  updateDepartmentResponseActive: (responseId: number, active: boolean) => void;
  refresh: () => void;
  updateSchema: (newSchema: ISchemaDto, action: string) => void;
  role: string;
  schemas: ISchemaDto[];
}>({
  companyId: '',
  companyName: '',
  departments: [],
  departmentResponses: [],
  updateDepartmentResponseName: () => {},
  updateDepartmentResponseActive: () => {},
  refresh: () => {},
  updateSchema: () => {},
  role: '',
  schemas: [],
});
