import React, { useEffect, useState } from 'react';
import { Actions } from '../Constants';
import { authenticatedFetch } from './AuthenticationContext';
import Spinner from './Spinner/Spinner';
import {
  IActionResponseDto,
  ICategoryResponseDto,
  IQuestionResponseDto,
  IResponseDto,
  ISectionResponseDto,
} from '../generated/ApiTypes';

interface Props {
  responseId: string;
}

export const ResponseProvider: React.FC<Props> = ({ children, responseId }) => {
  const [response, setResponse] = useState<IResponseDto>();

  useEffect(() => {
    authenticatedFetch('/api/Response/' + responseId)
      .then((response) => response.json())
      .then((data: IResponseDto) => {
        setResponse(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const refresh = (newResponse: IResponseDto) => {
    setResponse(newResponse);
  };

  const refreshCategory = (newCategory: ICategoryResponseDto) => {
    if (response == null) {
      return;
    }

    const newResponse = { ...response };

    const categoryIndex = newResponse.categories.findIndex(
      (c) => c.categoryResponseId == newCategory.categoryResponseId
    );

    newResponse.categories[categoryIndex] = newCategory;
    setResponse(newResponse);
  };

  const refreshSection = (
    categoryResponseId: number,
    newSection: ISectionResponseDto
  ) => {
    if (response == null) {
      return;
    }

    const newResponse = { ...response };
    const categoryToUpdate = newResponse.categories.find(
      (x) => x.categoryResponseId == categoryResponseId
    );

    if (!categoryToUpdate) {
      return;
    }

    const sectionIndex = categoryToUpdate.sections.findIndex(
      (x) => x.sectionResponseId == newSection.sectionResponseId
    );
    if (sectionIndex == null) {
      return;
    }

    categoryToUpdate.sections[sectionIndex] = newSection;

    // newResponse?.categories
    //   .find((c) => c.categoryResponseId == categoryResponseId)
    //   ?.sections.find(
    //     (s) => s.sectionResponseId == newSection.sectionResponseId
    //   ) == newSection;

    setResponse(newResponse);
  };

  const refreshQuestion = (
    categoryResponseId: number,
    sectionResponseId: number,
    newQuestion: IQuestionResponseDto
  ) => {
    if (!response) {
      return;
    }

    const newResponse = { ...response };

    const categoryToUpdate = newResponse.categories.find(
      (x) => x.categoryResponseId == categoryResponseId
    );

    if (!categoryToUpdate) {
      return;
    }

    const sectionIndex = categoryToUpdate.sections.findIndex(
      (x) => x.sectionResponseId == sectionResponseId
    );

    const section = categoryToUpdate.sections[sectionIndex];

    const questionIndex = section.questions.findIndex(
      (x) => x.questionId == newQuestion.questionId
    );

    section.questions[questionIndex] = newQuestion;

    // var newSection = newResponse?.categories
    //   .find((c) => c.categoryResponseId == categoryResponseId)
    //   ?.sections.find((s) => s.sectionResponseId == sectionResponseId);
    // newSection?.questions.find((q) => q.questionId == newQuestion.questionId) ==
    //   newQuestion;
    setResponse(newResponse);
  };

  const refreshAction = (
    categoryResponseId: number,
    sectionResponseId: number,
    actionIndex: number,
    action: string,
    newAction: IActionResponseDto
  ) => {
    if (!response) {
      return;
    }

    const newResponse = { ...response };

    const categoryToUpdate = newResponse.categories.find(
      (x) => x.categoryResponseId == categoryResponseId
    );

    if (!categoryToUpdate) {
      return;
    }

    const sectionIndex = categoryToUpdate.sections.findIndex(
      (x) => x.sectionResponseId == sectionResponseId
    );

    const section = categoryToUpdate.sections[sectionIndex];

    if (action == Actions.UPDATE) {
      section.actions[actionIndex] = newAction;
    } else if (action == Actions.CREATE) {
      section.actions = [...section.actions, newAction];
    } else if (action == Actions.DELETE) {
      section.actions.splice(actionIndex, 1);
    }
    setResponse(newResponse);
  };

  return response ? (
    <ResponseContext.Provider
      value={{
        response,
        refresh,
        refreshCategory,
        refreshSection,
        refreshQuestion,
        refreshAction,
      }}
    >
      {children}
    </ResponseContext.Provider>
  ) : (
    <Spinner />
  );
};

export const ResponseContext = React.createContext<{
  response?: IResponseDto;
  refresh: (newResponse: IResponseDto) => void;
  refreshCategory: (newCategory: ICategoryResponseDto) => void;
  refreshSection: (
    categoryResponseId: number,
    newSection: ISectionResponseDto
  ) => void;
  refreshQuestion: (
    categoryResponseId: number,
    sectionResponseId: number,
    newQuestion: IQuestionResponseDto
  ) => void;
  refreshAction: (
    categoryResponseId: number,
    sectionResponseId: number,
    actionIndex: number,
    action: string,
    newAction: IActionResponseDto
  ) => void;
}>({
  refresh: async () => {},
  refreshCategory: async () => {},
  refreshSection: async () => {},
  refreshQuestion: async () => {},
  refreshAction: async () => {},
});
