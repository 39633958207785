import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import Home from './pages/Home/Home';
import Response from './pages/Response/Response';
import DashboardRead from './pages/Dashboard/DashboardRead';
import './App.scss';
import { AuthContext } from './components/AuthenticationContext';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { UserAdministration } from './pages/Administration/UserAdministration';
import { CompanyAdministration } from './pages/Administration/CompanyAdministration';
import { DepartmentAdministrationContainer } from './pages/Administration/DepartmentAdministration';
import { CompanyUserAdministration } from './pages/Administration/CompanyUserAdministration';
import { SelfProvider } from './components/SelfContext';
import LandingPage from './pages/Landing/LandingPage';
import { SchemaAdministration } from './pages/Administration/SchemaAdministration';
import { ResponseAdministration } from './pages/Administration/ResponseAdministration'; // TODO: REMOVE

const App = () => {
  const authContext = useContext(AuthContext);
  return (
      <SelfProvider>
        {authContext.isAuthenticated ? (
          <Layout>
            <div className="outerWrapper">
              <Switch>
                <Route path="/" component={Home} exact />
                <Route path="/response/:responseId(\d+)" component={Response} />
                <Route
                  path="/dash/company/:companyId"
                  component={DashboardRead}
                />
                <Route
                  path="/dash/department/:companyId/:departmentId"
                  component={DashboardRead}
                />
                <Route path="/users" component={UserAdministration} />
                <Route path="/companies" component={CompanyAdministration} />
                <Route path="/schemas" component={SchemaAdministration} />
                <Route
                  path="/departments"
                  component={DepartmentAdministrationContainer}
                />
                <Route
                  path="/company/users"
                  component={CompanyUserAdministration}
                />
                {/* TODO: REMOVE: */}
                <Route path="/responses" component={ResponseAdministration} />
                <Route component={PageNotFound} />
              </Switch>
            </div>
          </Layout>
        ) : (
          <Route path="/" component={LandingPage} />
        )}
      </SelfProvider>
  );
};

export default App;
