import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import Category from '../Category/Category';
import PageNotFound from '../PageNotFound/PageNotFound';
import { ResponseProvider } from '../../components/ResponseContext';

const Response = () => {
  const { url, params } = useRouteMatch<{ responseId: string }>();

  return (
    <ResponseProvider responseId={params.responseId}>
      <Switch>
        <Route path={`${url}/`} exact>
          <Dashboard />
        </Route>
        <Route path={`${url}/category/:id`}>
          <Category />
        </Route>
        <Route component={PageNotFound} />
      </Switch>
    </ResponseProvider>
  );
};

export default Response;
