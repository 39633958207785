export enum Actions {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}

export enum ExposureLevel {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

export enum Languages {
  ALL = 'all',
  ENGLISH = 'en',
  NORWEGIAN = 'no',
}

export enum HomeTabs {
  EDIT = 'edit',
  READ = 'read',
  NOACCESS = 'noaccess',
}

export enum Roles {
  ADMIN = 'Admin',
  OWNER = 'Owner',
  READER = 'Reader',
  WRITER = 'Writer',
}

export enum Departments {
  LEAF = 'LeafDepartment',
  BRANCH = 'BranchDepartment',
}
