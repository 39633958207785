import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext, authenticatedFetch } from "../../components/AuthenticationContext";
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import Styles from './UserAdministration.module.scss';
import {
  IDepartmentDto,
  IUserDepartmentDto,
  IUserDto,
} from '../../generated/ApiTypes';
import { combineClasses } from '../../utils/Utils';
import { SelfContext } from '../../components/SelfContext';
import { Departments, Roles } from '../../Constants';

export const CompanyUserAdministration = () => {
  const { t } = useTranslation();
  const { companyId, companyName, role, departments, refresh } =
    useContext(SelfContext);
  const [companyAccess, setCompanyAccess] = useState(
    (role == Roles.OWNER || role == Roles.ADMIN) &&
      companyId != null &&
      companyId != ''
  );
  const [users, setUsers] = useState<IUserDto[]>([]);
  const [subDepartments, setSubDepartments] = useState<IDepartmentDto[]>([]);
  const refreshUsers = useCallback(() => {
    refresh();
    authenticatedFetch(`api/users/company/${companyId}`, {})
      .then((r) => r.json())
      .then((users: IUserDto[]) => setUsers(users.filter((u) => u.mail)));
    let subDep: IDepartmentDto[] = [];
    if (companyAccess) {
      authenticatedFetch(`api/departments/${companyId}`, {})
        .then((r) => r.json())
        .then((departments: IDepartmentDto[]) =>
          setSubDepartments(departments)
        );
    } else {
      departments
        .filter((d) => d.role == Roles.OWNER)
        .forEach((d) => {
          authenticatedFetch(
            `api/departments/${companyId}/${d.departmentId}`,
            {}
          )
            .then((r) => r.json())
            .then((deps: IDepartmentDto[]) => {
              subDep = subDep.concat(
                deps.filter(
                  (d) => !subDep.some((sd) => sd.departmentId == d.departmentId)
                )
              );
              setSubDepartments(subDep);
            });
        });
    }
  }, []);
  useEffect(refreshUsers, []);
  useEffect(() => {
    setCompanyAccess(
      (role == Roles.ADMIN || role == Roles.OWNER) &&
        companyId != null &&
        companyId != ''
    );
  }, [, role, companyId]);
  return (
    <div className={combineClasses(Styles.AdminPage, Styles.WithBackground)}>
      <div className={Styles.row}>
        <h1>
          {companyName} - {t('User Administration')}
        </h1>
        <button onClick={refreshUsers} className="btn blue">
          {t('Refresh')}
        </button>
      </div>
      <div className={Styles.UserCardsContainer}>
        {users?.map((u) => (
          <UserCard
            key={u.id}
            user={u}
            departments={subDepartments}
            companyId={companyId}
            companyAccess={companyAccess}
            refresh={refreshUsers}
          />
        )) ?? t('Loading users')}
        {companyAccess && (
          <UserCard
            departments={subDepartments}
            companyId={companyId}
            refresh={refreshUsers}
          />
        )}
      </div>
    </div>
  );
};

const useString = (masterString?: string) => {
  const state = useState(masterString);
  useEffect(() => state[1](masterString), [masterString]);
  return state;
};

const UserCard = (props: {
  user?: IUserDto;
  departments: IDepartmentDto[];
  companyId: string;
  companyAccess?: boolean;
  refresh?: () => void;
}) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const [displayName, setDisplayName] = useString(
    props.user?.displayName ?? ''
  );
  const [mail, setMail] = useString(props.user?.mail ?? '');
  const [role, setRole] = useString(props.user?.role);
  return (
    <div className={Styles.UserCard}>
      <label className={Styles.UserAttribute}>
        {t('Name')}
        {props.user ? (
          <span>{displayName ?? ':/'}</span>
        ) : (
          <input
            placeholder={t('Name')}
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
          />
        )}
      </label>
      <label className={Styles.UserAttribute}>
        {t('E-mail')}
        {props.user ? (
          <span>{mail ?? ':/'}</span>
        ) : (
          <input
            placeholder={t('E-mail')}
            value={mail}
            onChange={(e) => setMail(e.target.value)}
          />
        )}
      </label>
      <label className={Styles.UserAttribute}>
        {t('Role')}
        <Select
          isClearable={true}
          isDisabled={
            role === Roles.ADMIN || (props.user && !props.companyAccess)
          }
          value={
            role === Roles.ADMIN
              ? { value: Roles.ADMIN, label: t('Admin') }
              : role === Roles.OWNER
              ? { value: Roles.OWNER, label: t('Company owner') }
              : role === Roles.READER
              ? { value: Roles.READER, label: t('Company read access') }
              : undefined
          }
          options={[
            { value: Roles.OWNER, label: t('Company owner') },
            { value: Roles.READER, label: t('Company read access') },
          ]}
          placeholder={t('Select')}
          onChange={
            props.user
              ? (selected: any) =>
                  authContext.authenticatedFetchWithToast(`api/users/${props.user?.id}`, {
                    method: 'PUT',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      role: selected?.value,
                      companyId: props.companyId,
                      departments: props.user?.departments,
                    }),
                  })
                    .then((r) => r.json())
                    .then((r) => setRole(r.role))
              : (selected: any) => setRole(selected?.value)
          }
        />
      </label>

      {props.user && (
        <div className={Styles.DepartmentSelectContainer}>
          <div className={Styles.DepartmentSelect}>
            <label className={Styles.DepartmentAttribute}>
              {t('Department')}
            </label>
            <label className={Styles.DepartmentAttribute}>{t('Access')}</label>
            <label className={Styles.DepartmentAttribute}></label>
          </div>
          {props.user?.departments?.map((d) => (
            <DepartmentSelect
              key={d.id}
              user={props.user}
              userDepartment={d}
              departments={props.departments}
              companyId={props.companyId}
              refresh={props.refresh}
            />
          )) ?? t('Loading users')}
          <DepartmentSelect
            user={props.user}
            departments={props.departments}
            companyId={props.companyId}
            refresh={props.refresh}
          />
        </div>
      )}
      {props.user ? (
        props.companyAccess && (
          <button
            className="btn blue"
            disabled={role == Roles.OWNER || role == Roles.ADMIN}
            onClick={async () => {
              if (
                confirm(
                  t('Confirm delete', {
                    type: t('User').toLowerCase(),
                    name: props.user?.displayName,
                  })
                )
              ) {
                await authContext.authenticatedFetchWithToast(`api/users/${props.user?.id}`, {
                  method: 'delete',
                });
                props.refresh?.();
              }
            }}
          >
            {t('Delete user')}
          </button>
        )
      ) : (
        <button
          className="btn blue"
          disabled={displayName == '' || mail == ''}
          onClick={async () => {
            await authContext.authenticatedFetchWithToast('api/users', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                displayName,
                mail,
                companyId: props.companyId,
                role,
              }),
            });
            props.refresh?.();
            setDisplayName('');
            setMail('');
          }}
        >
          {t('Add user')}
        </button>
      )}
    </div>
  );
};

const DepartmentSelect = (props: {
  user?: IUserDto;
  userDepartment?: IUserDepartmentDto;
  departments: IDepartmentDto[];
  companyId: string;
  refresh?: () => void;
}) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const roleOptions = [
    { value: Roles.WRITER, label: t('Write access') },
    { value: Roles.READER, label: t('Read access') },
    { value: Roles.OWNER, label: t('Department owner') },
  ];
  const [departmentOptions, setDepartmentOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const [departmentId, setDepartmentId] = useState(
    props.userDepartment?.departmentId ?? ''
  );
  const [departmentName, setDepartmentName] = useState(
    props.userDepartment?.departmentName ?? ''
  );
  const [departmentType, setDepartmentType] = useState(
    props.departments.find((d) => d.departmentId == departmentId)?.type ?? ''
  );
  const [role, setRole] = useState(
    roleOptions.find((r) => r.value == props.userDepartment?.role)?.label ?? ''
  );
  useEffect(() => {
    setDepartmentOptions(
      props.departments
        .filter(
          (d) =>
            !props.user?.departments.some(
              (ud) => ud.departmentId == d.departmentId
            )
        )
        .filter((d) =>
          role == Roles.WRITER
            ? d.type == Departments.LEAF
            : role == Roles.READER || role == Roles.OWNER
            ? d.type == Departments.BRANCH
            : d
        )
        .map((d) => ({ value: d.departmentId, label: d.name }))
    );
  }, [, role, props.user?.departments]);

  return (
    <div className={Styles.DepartmentSelect}>
      <label className={Styles.DepartmentAttribute}>
        {props.userDepartment ? (
          <span>{departmentName}</span>
        ) : (
          <Select
            isClearable={true}
            value={
              departmentOptions.find(
                (d) => d.value === departmentId && d.label === departmentName
              ) ?? undefined
            }
            options={departmentOptions ?? [{ value: '', label: '' }]}
            placeholder={t('Select')}
            onChange={(selected: any) => {
              if (selected) {
                setDepartmentId(selected.value);
                setDepartmentName(selected.label);
                const type =
                  props.departments.find(
                    (d) => d.departmentId === selected.value
                  )?.type ?? '';
                setDepartmentType(type);
                if (type == Departments.LEAF) {
                  setRole(Roles.WRITER);
                }
              } else {
                setDepartmentId('');
                setDepartmentName('');
                setDepartmentType('');
                setRole('');
              }
            }}
          />
        )}
      </label>
      <label className={Styles.DepartmentAttribute}>
        {props.userDepartment ? (
          <span>{role}</span>
        ) : (
          <Select
            isClearable={true}
            isDisabled={departmentType === Departments.LEAF}
            value={roleOptions.find((r) => r.value == role) ?? undefined}
            options={
              departmentType === Departments.BRANCH
                ? roleOptions.filter((ro) => !(ro.value === Roles.WRITER))
                : roleOptions
            }
            placeholder={t('Select')}
            onChange={(selected: any) => setRole(selected?.value ?? '')}
          />
        )}
      </label>

      <div className={Styles.DepartmentAttribute}>
        {props.userDepartment ? (
          <button
            className={combineClasses('btn blue', Styles.deleteAccessButton)}
            disabled={
              !props.departments.some(
                (d) => d.departmentId === props.userDepartment?.departmentId
              )
            }
            onClick={async () => {
              if (
                confirm(
                  t('Confirm delete', {
                    type: t('Access to department').toLowerCase(),
                    name: props.userDepartment?.departmentName,
                  })
                )
              ) {
                await authContext.authenticatedFetchWithToast(`api/users/${props.user?.id}`, {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    companyId: props.companyId,
                    departments: props.user?.departments.filter(
                      (d) => d.id != props.userDepartment?.id
                    ),
                    role: props.user?.role,
                  }),
                });
                props.refresh?.();
              }
            }}
          >
            {t('Delete department access')}
          </button>
        ) : (
          <button
            className={combineClasses('btn blue', Styles.deleteAccessButton)}
            disabled={departmentId == '' || departmentName == '' || role == ''}
            onClick={async () => {
              await authContext.authenticatedFetchWithToast(`api/users/${props.user?.id}`, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  companyId: props.companyId,
                  departments: props.user?.departments
                    ? props.user?.departments.concat([
                        {
                          id: '0',
                          departmentId,
                          departmentName,
                          role,
                          parentPath: '',
                        },
                      ])
                    : [
                        {
                          id: '0',
                          departmentId,
                          departmentName,
                          role,
                          parentPath: '',
                        },
                      ],
                  role: props.user?.role,
                }),
              });
              setDepartmentId('');
              setDepartmentName('');
              setDepartmentType('');
              setRole('');
              props.refresh?.();
            }}
          >
            {t('Add department access')}
          </button>
        )}
      </div>
    </div>
  );
};
