import React, { useContext, useEffect, useState } from 'react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import styles from './Dashboard.module.scss';
import { useTranslation } from 'react-i18next';
import { authenticatedFetch } from '../../components/AuthenticationContext';
import {
  ICategoryEvalueDto,
  IDepartmentEvalueDto,
} from '../../generated/ApiTypes';
import { EvalueMeter } from '../../components/EvalueMeter/EvalueMeter';
import { combineClasses } from '../../utils/Utils';
import Spinner from '../../components/Spinner/Spinner';
import { Icon } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { roundTo, roundToUp, roundToDown } from 'round-to';

const DashboardRead = () => {
  const { i18n } = useTranslation();
  const { url, params } = useRouteMatch<{
    companyId: string;
    departmentId: string;
  }>();
  const [companyEvalue, setCompanyEvalue] = useState<IDepartmentEvalueDto>();
  const [selectedDepartment, setSelectedDepartment] =
    useState<IDepartmentEvalueDto>();
  const [categoryTitles, setCategoryTitles] =
    useState<Array<{ order: number; title: string }>>();
  useEffect(() => {
    authenticatedFetch(
      `api/departments/aggregatedevalues/${params.companyId}`,
      {}
    )
      .then((r) => r.json())
      .then((s: IDepartmentEvalueDto) => {
        setCompanyEvalue(s);
        setSelectedDepartment(s);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (i18n.language == 'nb-NO') {
      authenticatedFetch(`api/categories/language/${1}`)
        .then((r) => r.json())
        .then((r) => r.result)
        .then((catInfo: Array<{ order: number; title: string }>) => {
          setCategoryTitles(
            catInfo.map((c) => ({ order: c.order, title: c.title }))
          );
        });
    } else if (i18n.language == 'en-US') {
      authenticatedFetch(`api/categories/language/${2}`)
        .then((r) => r.json())
        .then((r) => r.result)
        .then((catInfo: Array<{ order: number; title: string }>) => {
          setCategoryTitles(
            catInfo.map((c) => ({ order: c.order, title: c.title }))
          );
        });
    }
  }, [, i18n.language]);

  return companyEvalue && selectedDepartment && categoryTitles ? (
    <>
      <div className={combineClasses(styles.wrapper2, styles.wrapperRead)}>
        <EvaluesOverview
          companyEvalue={selectedDepartment}
          categoryTitles={categoryTitles}
        />
        <CompanyOverview
          companyEvalue={companyEvalue}
          selectedDepartment={selectedDepartment}
          setSelectedDepartment={setSelectedDepartment}
        />
      </div>
    </>
  ) : (
    <Spinner />
  );
};

const EvaluesOverview = (props: {
  companyEvalue?: IDepartmentEvalueDto;
  categoryTitles?: Array<{ order: number; title: string }>;
}) => {
  const { t } = useTranslation();
  const relevantCategories = [];
  const irrelevantCategories = [];
  const name = props.companyEvalue?.name ?? '';
  const averageEverdi = props.companyEvalue ? props.companyEvalue.evalue : 0;
  for (const category of props.companyEvalue?.categories ?? []) {
    if (category.activeInForms > 0) {
      relevantCategories.push({
        ...category,
        title: props.categoryTitles?.find((c) => c.order == category.order)
          ?.title,
      });
    } else
      irrelevantCategories.push({
        ...category,
        title: props.categoryTitles?.find((c) => c.order == category.order)
          ?.title,
      });
  }

  return (
    <div className={combineClasses(styles.evalues, styles.box)}>
      <div className={combineClasses(styles.row)}>
        <h1>
          {name}{' '}
          <h2>
            {t('Active responses')}: {props.companyEvalue?.activeForms}
          </h2>
        </h1>

        <div className={styles.total}>
          <h1>
            {t('Total value')}: {averageEverdi.toFixed(1)}
          </h1>
          <div className={styles.meter}>
            <EvalueMeter value={averageEverdi} hideText={true} />
          </div>
        </div>
      </div>
      <div className={styles.categories}>
        {relevantCategories.map((category) => (
          <CategoryCard key={category.order} {...category} />
        ))}

        <div className={styles.separator}>{t('No exposure')}</div>

        {irrelevantCategories.map((category) => (
          <CategoryCard key={category.order} {...category} />
        ))}
      </div>
    </div>
  );
};

interface CategoryCardProps extends ICategoryEvalueDto {
  title?: string;
}

const CategoryCard = (props: CategoryCardProps) => {
  const { t } = useTranslation();
  const avgEvalue =
    props.activeInForms > 0
      ? roundTo(props.evalue / props.activeInForms, 1)
      : 0;
  const getEvalueStatusText = () => {
    if (avgEvalue >= 60) return t('Must be improved');
    if (avgEvalue >= 25) return t('Should be improved');
    return t('Acceptable');
  };

  return (
    <div className={combineClasses(styles.card)}>
      <h2 className={styles.title}>{props.title}</h2>
      {props.activeInForms > 0 ? (
        <>
          <>
            <div className={styles.categoryMeter}>
              <EvalueMeter value={avgEvalue} hideText={true} />
            </div>
          </>

          <div className={styles.footer}>
            <p>
              {t('Value')} {avgEvalue}
            </p>
            <p>
              {t('Active responses')}: {props.activeInForms}
            </p>
            <p>
              <b>status:</b> {getEvalueStatusText()}
            </p>
          </div>
        </>
      ) : (
        <div className={combineClasses(styles.button, styles.inactive)}>
          {t('Inactive')}
        </div>
      )}
    </div>
  );
};

const CompanyOverview = (props: {
  companyEvalue?: IDepartmentEvalueDto;
  selectedDepartment?: IDepartmentEvalueDto;
  setSelectedDepartment: (department: IDepartmentEvalueDto) => void;
}) => {
  const { t } = useTranslation();
  const selectedDepartmentId = props.selectedDepartment?.id ?? 0;
  return (
    <div className={combineClasses(styles.departments, styles.sidebar)}>
      <div className={combineClasses(styles.branch)}>
        <h2>
          <Icon name="file alternate outline" />
          {t('Active responses')}
        </h2>{' '}
      </div>
      <h1
        className={styles.companyNameButton}
        onClick={() =>
          props.companyEvalue &&
          props.setSelectedDepartment(props.companyEvalue)
        }
      >
        {props.companyEvalue?.name}
      </h1>
      <div className={combineClasses(styles.branch)}></div>
      <div className={styles.TreeContainer}>
        {props.companyEvalue?.subDepartments?.map((d) => (
          <DepartmentCard
            key={d.id}
            departmentEvalue={d}
            selectedDepartmentId={selectedDepartmentId}
            setSelectedDepartment={props.setSelectedDepartment}
          />
        )) ?? t('Loading departments')}
      </div>
    </div>
  );
};

const DepartmentCard = (props: {
  departmentEvalue: IDepartmentEvalueDto;
  selectedDepartmentId: number;
  setSelectedDepartment: (department: IDepartmentEvalueDto) => void;
}) => {
  const { t } = useTranslation();
  const [displaySubDepartments, setDisplaySubDepartments] = useState(false);

  return (
    <>
      <div className={styles.DepartmentCard}>
        <div className={styles.DepartmentInfo}>
          <div
            className={styles.DepartmentButton}
            onClick={() => {
              props.setSelectedDepartment(props.departmentEvalue);
              setDisplaySubDepartments(!displaySubDepartments);
            }}
          >
            <Icon
              name={
                Array.isArray(props.departmentEvalue.subDepartments) &&
                displaySubDepartments
                  ? 'angle down'
                  : 'angle right'
              }
            />
            <p className={styles.name}>{props.departmentEvalue.name ?? ''}</p>
          </div>
        </div>
        {displaySubDepartments && (
          <div className={styles.SubDepartment}>
            {Array.isArray(props.departmentEvalue.subDepartments)
              ? props.departmentEvalue.subDepartments.map((d) => (
                  <DepartmentCard
                    key={d.id}
                    departmentEvalue={d}
                    selectedDepartmentId={props.selectedDepartmentId}
                    setSelectedDepartment={props.setSelectedDepartment}
                  />
                ))
              : null}
          </div>
        )}
      </div>
    </>
  );
};

export default DashboardRead;
