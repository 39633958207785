import React, {
  ChangeEvent,
  Dispatch,
  memo,
  Reducer,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import styles from '../Home/Home.module.scss';
import { AuthContext, authenticatedFetch } from '../../components/AuthenticationContext';
import { useTranslation } from 'react-i18next';
import Styles from './UserAdministration.module.scss';
import {
  ICategoryDetailDto,
  IQuestionDetailDto,
  ISchemaDetailDto,
  ISchemaDto,
  ISectionDetailDto,
} from '../../generated/ApiTypes';
import {
  Link,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import Spinner from '../../components/Spinner/Spinner';
import { Actions, Languages } from '../../Constants';
import { SelfContext } from '../../components/SelfContext';
import { combineClasses } from '../../utils/Utils';

export const SchemaAdministration: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/:schemaId(\\d+)`} component={SchemaEditor} />
      <Route path={`${path}`} exact component={SchemasOverview} />
    </Switch>
  );
};

const SchemasOverview = () => {
  const { t } = useTranslation();
  const [schemas, setSchemas] = useState<ISchemaDto[]>();
  const refresh = useCallback(() => {
    authenticatedFetch('api/Schema/')
      .then((r) => r.json())
      .then(setSchemas);
  }, []);
  useEffect(refresh, []);
  return (
    <div className={combineClasses(Styles.AdminPage, Styles.WithBackground)}>
      <h1>{t('Form Overview')}</h1>
      <div className={Styles.UserCardsContainer}>
        {schemas?.map((s) => (
          <SchemaCard key={s.schemaId} schema={s} refresh={refresh} />
        )) ?? t('Loading forms')}
      </div>
      <button onClick={refresh} className='btn blue'>
        {t('Refresh')}
      </button>
      <CreateSchema refresh={refresh} />
    </div>
  );
};

const CreateSchema = (props: { refresh: () => void }) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const companyContext = useContext(SelfContext);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    Languages.NORWEGIAN,
  );

  return (
    <>
      <div className={styles.separator}>{t('Add new')}</div>

      <form
        className={styles.newResponse}
        onSubmit={(e) => {
          e.preventDefault();
          // @ts-ignore
          const textInput = e.target.querySelector('input[name="name"]');
          const name = textInput.value;
          if (name == '') {
            return;
          }

          authContext.authenticatedFetchWithToast('/api/schema', {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              name: name,
              language: selectedLanguage,
            }),
          })
            .then((r) => r.json())
            .then((newSchema: ISchemaDto) => {
              companyContext.updateSchema(newSchema, Actions.CREATE);
              textInput.value = '';
              props.refresh();
            });
        }}
      >
        <input type='text' name='name' placeholder={t('Name')} />
        <select
          className={styles.select}
          onChange={(e) => setSelectedLanguage(e.target.value)}
        >
          <option value={Languages.NORWEGIAN}>{t('Norwegian')}</option>
          <option value={Languages.ENGLISH}>{t('English')}</option>
        </select>

        <button className='btn blue'>{t('Add new')}</button>
      </form>
    </>
  );
};

const SchemaCard = (props: { schema: ISchemaDto; refresh: () => void }) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const { path } = useRouteMatch();
  const companyContext = useContext(SelfContext);
  const deleteSchema = () => {
    if (
      confirm(
        t('Confirm delete', {
          type: t('form').toLowerCase(),
          name: props.schema.name,
        }),
      )
    ) {
      authContext.authenticatedFetchWithToast('/api/schema/' + props.schema.schemaId, {
        method: 'delete',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(() => {
        const schema = props.schema;
        if (!schema) return;
        const newSchema: ISchemaDto = {
          schemaId: schema.schemaId,
          name: schema.name,
          language: schema.language,
          active: schema.active,
          date: schema.date,
        };
        companyContext.updateSchema(newSchema, Actions.DELETE);
        props.refresh();
      });
    }
  };

  const copySchema = () => {
    authContext.authenticatedFetchWithToast(`/api/schema/${props.schema.schemaId}/copy`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(() => {
      props.refresh();
    });
  };

  return (
    <div className={Styles.UserCard}>
      <Link to={`${path}/${props.schema.schemaId}`}>
        <span className={Styles.UserAttribute}>{props.schema.name}</span>
      </Link>
      <span className={Styles.UserAttribute}>{props.schema.language}</span>
      <span className={Styles.UserAttribute}>
        {props.schema.active ? t('active') : t('inactive')}
      </span>
      <div className={Styles.RowButtons}>
        <button className='btn blue' onClick={() => deleteSchema()}>
          {t('Delete')}
        </button>
        <button className='btn blue' onClick={() => copySchema()}>
          {t('Copy')}
        </button>
      </div>
    </div>
  );
};

const parseEventTarget = ({
                            name,
                            value,
                            type,
                            checked,
                          }: EventTarget & HTMLInputElement) => {
  return {
    [name]:
      type === 'checkbox'
        ? checked
        : type === 'number'
          ? Number.parseInt(value)
          : value,
  };
};

type SchemaProperties = Omit<ISchemaDetailDto, 'categories'>;
const SchemaEditor = () => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const { schemaId } = useParams<{ schemaId: string }>();
  const history = useHistory();
  const companyContext = useContext(SelfContext);
  const [originalProperties, setOriginalProperties] =
    useState<SchemaProperties>();
  const [schema, setSchema] = useState<ISchemaDetailDto>();
  const refresh = useCallback(() => {
    authenticatedFetch(`api/Schema/${schemaId}`)
      .then((r) => r.json())
      .then((s: ISchemaDetailDto) => {
        setSchema(s);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { categories, ...schemaProperties } = s;
        setOriginalProperties(schemaProperties);
      });
  }, []);
  useEffect(refresh, []);
  const change = useCallback((e) => {
    setSchema((s) => s && { ...s, ...parseEventTarget(e.target) });
  }, []);
  const saveSchemaProperties = useCallback(() => {
    authContext.authenticatedFetchWithToast(`/api/schema/${schema?.schemaId}`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(schema),
    })
      .then((r) => r.json())
      .then((schemaProperties: SchemaProperties) => {
        setOriginalProperties(schemaProperties);

        if (!schema) return;
        const newSchema: ISchemaDto = {
          schemaId: schema.schemaId,
          name: schema.name,
          language: schema.language,
          active: schema.active,
          date: schema.date,
        };
        companyContext.updateSchema(newSchema, Actions.UPDATE);
      });
  }, [schema, authContext.authenticatedFetchWithToast]);
  const deleteSchema = useCallback(() => {
    if (
      confirm(
        t('Confirm delete', {
          type: t('form').toLowerCase(),
          name: schema?.name,
        }),
      )
    ) {
      authContext.authenticatedFetchWithToast(`/api/schema/${schema?.schemaId}`, {
        method: 'delete',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(() => {
        history.push('/schemas');
        if (!schema) return;
        const newSchema: ISchemaDto = {
          schemaId: schema.schemaId,
          name: schema.name,
          language: schema.language,
          active: schema.active,
          date: schema.date,
        };
        companyContext.updateSchema(newSchema, Actions.DELETE);
      });
    }
  }, [schema, authContext.authenticatedFetchWithToast]);
  const deleteCategory = useCallback((id: number, title: string) => {
    if (
      confirm(
        t('Confirm delete', { type: t('Category').toLowerCase(), name: title }),
      )
    ) {
      authContext.authenticatedFetchWithToast(`/api/categories/${id}`, {
        method: 'delete',
      }).then(() =>
        setSchema(
          (s) =>
            s && {
              ...s,
              categories: [...s.categories.filter((c) => c.id !== id)],
            },
        ),
      );
    }
  }, [authContext.authenticatedFetchWithToast]);
  const createCategory = useCallback(() => {
    authContext.authenticatedFetchWithToast('/api/categories', {
      method: 'POST',
      headers: {
        'content-type': 'application/problem+json',
      },
      body: JSON.stringify({ schemaId: Number.parseInt(schemaId) }),
    })
      .then((r) => r.json())
      .then((c: ICategoryDetailDto) =>
        setSchema(
          (s) =>
            s && {
              ...s,
              categories: [c, ...s.categories],
            },
        ),
      );
  }, [authContext.authenticatedFetchWithToast]);
  const changed =
    schema?.name !== originalProperties?.name ||
    schema?.language !== originalProperties?.language ||
    schema?.active !== originalProperties?.active;
  return schema ? (
    <div className={Styles.AdminPage}>
      <div className={Styles.SchemaProperties}>
        <h1>{t('Form Administration')}</h1>
        <SchemaInput change={change} data={schema} index={'name'} />
        <SchemaInput change={change} data={schema} index={'language'} />
        <SchemaInput change={change} data={schema} index={'active'} />
        <div className={Styles.ButtonControls}>
          <button className='btn blue' onClick={() => deleteSchema()}>
            {t('Delete')} {t('form')}
          </button>
          <button className='btn blue' onClick={createCategory}>
            {t('Add')} {t('category')}
          </button>
          {changed ? (
            <button className='btn blue' onClick={saveSchemaProperties}>
              {t('Save')} {t('form')} {t('properties')}
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div>
        {schema.categories.map((category) => (
          <MemoizedSchemaCategory
            key={category.id}
            category={category}
            deleteCategory={deleteCategory}
          />
        ))}
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

const MemoizedSchemaCategory = memo(function SchemaCategory({
  category,
  deleteCategory,
}: {
  category: ICategoryDetailDto;
  deleteCategory: (id: number, title: string) => void;
}) {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const [categoryProperties, setCategoryProperties] = useState(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { sections, ...properties } = category;
    return properties;
  });
  const [originalCategoryProperties, setOriginalCategoryProperties] = useState(
    () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { sections, ...properties } = category;
      return properties;
    },
  );
  const [sections, setSections] = useState(category.sections);
  const change = useCallback((e) => {
    setCategoryProperties((p) =>
      p ? { ...p, ...parseEventTarget(e.target) } : p,
    );
  }, []);
  const saveCategoryProperties = useCallback(() => {
    authContext.authenticatedFetchWithToast(`/api/categories/${categoryProperties.id}`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(categoryProperties),
    })
      .then((r) => r.json())
      .then((changedSchema) => {
        setOriginalCategoryProperties(changedSchema);
        if (
          categoryProperties.alwaysActive &&
          categoryProperties.exposureLevelTitle !== ''
        ) {
          setCategoryProperties(changedSchema);
        }
      });
  }, [categoryProperties, authContext.authenticatedFetchWithToast]);
  const deleteSection = useCallback((id: number, title: string) => {
    if (
      confirm(
        t('Confirm delete', { type: t('Section').toLowerCase(), name: title }),
      )
    ) {
      authContext.authenticatedFetchWithToast(`/api/sections/${id}`, {
        method: 'delete',
      }).then(() =>
        setSections((s) => [...s.filter((section) => section.id !== id)]),
      );
    }
  }, [authContext.authenticatedFetchWithToast]);
  const createSection = useCallback(() => {
    authContext.authenticatedFetchWithToast('/api/sections', {
      method: 'POST',
      headers: {
        'content-type': 'application/problem+json',
      },
      body: JSON.stringify({ categoryId: category.id }),
    })
      .then((r) => r.json())
      .then((section: ISectionDetailDto) =>
        setSections((sections) => [section, ...sections]),
      );
  }, [authContext.authenticatedFetchWithToast]);
  const changed = Object.entries(categoryProperties).some(([k, v]) => {
    if (k === 'exposureLevelTitle' && categoryProperties.alwaysActive) {
      return false;
    }
    // @ts-ignore
    return v != originalCategoryProperties[k];
  });
  return (
    <div className={Styles.Category}>
      <div className={Styles.CategoryFields}>
        <SchemaInput
          change={change}
          data={categoryProperties}
          index={'title'}
        />
        <SchemaInput
          change={change}
          data={categoryProperties}
          index={'description'}
        />
        <SchemaInput
          change={change}
          data={categoryProperties}
          index={'exposure'}
        />
        {!categoryProperties.alwaysActive && (
          <SchemaInput
            change={change}
            data={categoryProperties}
            index={'exposureLevelTitle'}
          />
        )}
        <SchemaInput
          change={change}
          data={categoryProperties}
          index={'alwaysActive'}
        />
        <SchemaInput
          change={change}
          data={categoryProperties}
          index={'hasLimit'}
        />
        <SchemaInput
          change={change}
          data={categoryProperties}
          index={'order'}
        />
        <div className={Styles.ButtonControls}>
          <button
            className='btn blue'
            onClick={() => deleteCategory(category.id, category.title)}
          >
            {t('Delete')} {t('category')}
          </button>
          <button className='btn blue' onClick={createSection}>
            {t('Add')} {t('section')}
          </button>
          {changed ? (
            <button className='btn blue' onClick={saveCategoryProperties}>
              {t('Save')} {t('category')} {t('properties')}
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div>
        {sections.map((section) => (
          <SchemaSection
            key={section.id}
            section={section}
            deleteSection={deleteSection}
          />
        ))}
      </div>
    </div>
  );
});

const isNested = (action: SectionAction): action is NestedSectionAction =>
  'id' in action && 'action' in action;

const isChangedSection = (
  action: SectionAction,
): action is { changedSection: ISectionDetailDto } =>
  'changedSection' in action;

const isDeleteQuestion = (
  action: SectionAction,
): action is { deleteQuestion: number } => 'deleteQuestion' in action;

const isAddQuestion = (
  action: SectionAction,
): action is { addQuestion: boolean } => 'addQuestion' in action;

type NestedSectionAction = {
  id: number;
  action: React.ChangeEvent<HTMLInputElement>;
};
type SectionAction =
  | NestedSectionAction
  | React.ChangeEvent<HTMLInputElement>
  | { changedSection: ISectionDetailDto }
  | { deleteQuestion: number }
  | { addQuestion: boolean };
const sectionReducer: Reducer<ISectionDetailDto, SectionAction> = (
  state,
  action,
) => {
  if (isChangedSection(action)) {
    return { ...action.changedSection };
  } else if (isNested(action)) {
    const question = state.questions.find((q) => q.id === action.id);
    const { name, value, type } = action.action.target;
    if (question) {
      // @ts-ignore
      question[name] = type === 'number' ? Number.parseInt(value) : value;
    }
    return { ...state, questions: [...state.questions] };
  } else if (isDeleteQuestion(action)) {
    return {
      ...state,
      questions: [
        ...state.questions.filter((q) => q.id !== action.deleteQuestion),
      ],
    };
  } else if (isAddQuestion(action)) {
    const newQuestion: IQuestionDetailDto = {
      id: -1,
      text: '',
      value: 0,
      order: 0,
    };
    return { ...state, questions: [...state.questions, newQuestion] };
  } else {
    return { ...state, ...parseEventTarget(action.target) };
  }
};

const SchemaSection = (props: {
  section: ISectionDetailDto;
  deleteSection: (id: number, title: string) => void;
}) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const [section, change] = useReducer(sectionReducer, {
    ...props.section,
    questions: props.section.questions.map((q) => ({ ...q })),
  });
  const saveSection = useCallback(() => {
    const questionsDeleted =
      props.section.questions.length -
      section.questions.filter((x) => x.id != -1).length;
    const questionsDeletedText = questionsDeleted.toString();
    if (
      questionsDeleted == 0 ||
      confirm(
        t('Confirm delete', {
          type: t('questions').toLowerCase(),
          name: `(${questionsDeletedText})`,
        }),
      )
    ) {
      authContext.authenticatedFetchWithToast(`/api/sections/${section.id}`, {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(section),
      })
        .then((r) => r.json())
        .then((changedSection) => {
          Object.assign(
            props.section,
            JSON.parse(JSON.stringify(changedSection)),
          );
          change({ changedSection });
        });
    }
  }, [section, authContext.authenticatedFetchWithToast]);
  const changed = JSON.stringify(props.section) !== JSON.stringify(section);
  return (
    <div className={Styles.Section}>
      <SchemaInput change={change} data={section} index={'title'} />
      <SchemaInput change={change} data={section} index={'description'} />
      <SchemaInput change={change} data={section} index={'order'} />
      <SchemaInput
        change={change}
        data={section}
        index={'notApplicableQuestionId'}
      />
      <div className={Styles.Questions}>
        {section.questions.map((question) => (
          <SchemaQuestion
            key={question.id}
            question={question}
            change={change}
          />
        ))}
      </div>
      <div className={Styles.ButtonControls}>
        <button
          className='btn blue'
          onClick={() => props.deleteSection(section.id, section.title)}
        >
          {t('Delete')} {t('section')}
        </button>
        <button
          className='btn blue'
          onClick={() => change({ addQuestion: true })}
        >
          {t('Add')} {t('question')}
        </button>
        {changed ? (
          <button className='btn blue' onClick={saveSection}>
            {t('Save')} {t('section')}
          </button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const SchemaQuestion = (props: {
  question: IQuestionDetailDto;
  change: Dispatch<SectionAction>;
}) => {
  // const [question, change] = useReducer(questionReducer, props.question);
  const { t } = useTranslation();
  const change = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      props.change({ id: props.question.id, action: e });
    },
    [props.question.id],
  );
  const remove = useCallback(() => {
    props.change({ deleteQuestion: props.question.id });
  }, [props.question.id]);
  return (
    <div key={props.question.id}>
      <button onClick={remove} className='btn blue'>
        {t('Delete')}
      </button>
      <SchemaInput change={change} data={props.question} index={'text'} />
      <SchemaInput change={change} data={props.question} index={'value'} />
      <SchemaInput change={change} data={props.question} index={'order'} />
      <label className={Styles.Input}>
        <span style={{ textTransform: 'capitalize' }}>{t('id')}</span>
        <>
          <br />
          <input disabled={true} type='number' value={props.question.id} />
        </>
      </label>
    </div>
  );
};

const SchemaInput = <T, K extends keyof T>(props: {
  change?: (v: React.ChangeEvent<HTMLInputElement>) => void;
  data: T;
  index: K;
}) => {
  const { t } = useTranslation('form');
  const value = props.data[props.index];
  const name: string = props.index.toString();
  return (
    <label className={Styles.Input}>
      <span style={{ textTransform: 'capitalize' }}>{t(name)}</span>
      {typeof value === 'boolean' ? (
        <input
          onChange={props.change}
          name={name}
          type='checkbox'
          checked={value}
        />
      ) : typeof value === 'string' ? (
        <>
          <br />
          <input
            onChange={props.change}
            name={name}
            type='text'
            value={value}
          />
        </>
      ) : typeof value === 'number' ? (
        <>
          <br />
          <input
            onChange={props.change}
            name={name}
            type='number'
            value={value}
          />
        </>
      ) : (
        value
      )}
    </label>
  );
};
