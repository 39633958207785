import React, { useContext } from 'react';
import styles from './CategoryExposure.module.scss';
import { useTranslation } from 'react-i18next';
import { ResponseContext } from '../../components/ResponseContext';

interface ExposureLevelNotesProps {
  exposureLevelNotes: string | undefined;
  onChange: (newExposureLevelNotes: string) => void;
}

const ExposureLevelNotes: React.FC<ExposureLevelNotesProps> = ({
  exposureLevelNotes,
  onChange,
}) => {
  const { t } = useTranslation();
  const responseContext = useContext(ResponseContext);

  return (
    <div className={styles.notes}>
      <h1>{t('Notes')}</h1>
      <textarea
        placeholder={t('Type here')}
        value={exposureLevelNotes}
        onChange={(e) => onChange(e.target.value)}
        disabled={!responseContext.response?.active}
      />
    </div>
  );
};

export default ExposureLevelNotes;
