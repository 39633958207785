import React, { useState } from 'react';
import { INaceDto } from '../../generated/ApiTypes';
import { useTranslation } from 'react-i18next';
import Styles from './Multiselect.module.scss';
import { combineClasses } from '../../utils/Utils';

interface MultiselectProps {
  options: INaceDto[];
  selectedOptions: INaceDto[];
  onSelectedOptionsChange: (newSelectedOptions: INaceDto[]) => void;
}

export const Multiselect: React.FC<MultiselectProps> = ({
  options,
  selectedOptions,
  onSelectedOptionsChange,
}) => {
  const [displayOptions, setDisplayOptions] = useState(false);
  const [searchText, setSearchText] = useState('');
  const { t } = useTranslation();

  const handleOptionAdded = (option: INaceDto) => {
    if (selectedOptions.find((x) => x.naceId === option.naceId)) {
      return;
    }
    setSearchText('');
    onSelectedOptionsChange([...selectedOptions, { ...option }]);
  };

  const handleOptionDeleted = (option: INaceDto) => {
    onSelectedOptionsChange(
      selectedOptions.filter((x) => x.naceId !== option.naceId)
    );
  };

  const renderSelectedOptionsList = () => {
    return (
      <div>
        {selectedOptions.map((option) => (
          <span
            key={`selectedOption${option.naceId}`}
            className={Styles.selectedOption}
            onClick={() => handleOptionDeleted(option)}
          >
            {option.code}
          </span>
        ))}
      </div>
    );
  };

  const renderOptionsList = () => {
    return (
      <div
        className={displayOptions ? Styles.optionListContainer : Styles.hide}
      >
        <ul className={Styles.optionsContainer}>
          {options
            .filter(
              (option) =>
                option.code.includes(searchText) ||
                option.description.includes(searchText)
            )
            .sort((a, b) => (a.code > b.code ? 1 : -1))
            .map((option) => (
              <li
                key={`option${option.naceId}`}
                className={combineClasses(
                  Styles.option,
                  selectedOptions.find((x) => x.naceId === option.naceId)
                    ? Styles.disabledOption
                    : ''
                )}
                onClick={() => handleOptionAdded(option)}
              >
                <div className={Styles.optionCode}>{option.code}</div>
                <div className={Styles.optionDescription}>
                  {option.description}
                </div>
              </li>
            ))}
        </ul>
      </div>
    );
  };

  return (
    <div className={Styles.multiselectContainer}>
      <div className={Styles.searchWrapper}>
        {renderSelectedOptionsList()}
        <input
          type="text"
          placeholder={t('Nace codes')}
          className={Styles.searchBox}
          onFocus={() => setDisplayOptions(true)}
          onBlur={() => setTimeout(() => setDisplayOptions(false), 200)}
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
        />
      </div>
      {renderOptionsList()}
    </div>
  );
};
