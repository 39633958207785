import React, { useState, useContext, useEffect } from 'react';
import styles from './CategoryExposure.module.scss';
import { useTranslation } from 'react-i18next';
import { ResponseContext } from '../../components/ResponseContext';
import { AuthContext } from "../../components/AuthenticationContext";
import { ExposureLevel } from '../../Constants';
import { ICategoryResponseDto } from '../../generated/ApiTypes';
import ExposureLevelNotes from './ExposureLevelNotes';

const findExposureFromActiveAndBelowLimit = (
  active: boolean,
  belowLimit: boolean
) => {
  let exposure;
  if (active) {
    if (belowLimit) {
      exposure = ExposureLevel.MEDIUM;
    } else {
      exposure = ExposureLevel.HIGH;
    }
  } else {
    exposure = ExposureLevel.LOW;
  }
  return exposure;
};

const findActiveAndBelowLimitFromExposure = (exposure: string) => {
  let updatedCategory: { active: boolean; belowLimit: boolean };
  if (exposure == ExposureLevel.HIGH) {
    updatedCategory = { active: true, belowLimit: false };
  } else if (exposure == ExposureLevel.MEDIUM) {
    updatedCategory = { active: true, belowLimit: true };
  } else {
    updatedCategory = { active: false, belowLimit: false };
  }
  return updatedCategory;
};

interface CategoryExposureProps {
  categoryResponseId: number;
}

const CategoryExposure: React.FC<CategoryExposureProps> = ({
  categoryResponseId,
}) => {
  const responseContext = useContext(ResponseContext);
  const category = responseContext.response?.categories.find(
    (x) => x.categoryResponseId == categoryResponseId
  );
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);

  if (!category) {
    return <h1>Error</h1>;
  }

  const [prevExposure, setPrevExposure] = useState<string>();
  const [selectedExposure, setSelectedExposure] = useState<string>();
  const handleSelectedExposureChange = (newSelectedExposure: string) => {
    setSelectedExposure(newSelectedExposure);
  };

  const [prevExposureLevelNotes, setPrevExposureLevelNotes] =
    useState<string>();
  const [exposureLevelNotes, setExposureLevelNotes] = useState<string>();
  const handleExposureLevelNotesChange = (newExposureLevelNotes: string) => {
    setExposureLevelNotes(newExposureLevelNotes);
  };

  useEffect(() => {
    const exposure = findExposureFromActiveAndBelowLimit(
      category.active,
      category.belowLimit
    );
    setPrevExposure(exposure);
    setSelectedExposure(exposure);

    setPrevExposureLevelNotes(category.exposureLevelNotes);
    setExposureLevelNotes(category.exposureLevelNotes);
  }, []);

  const saveCategory = () => {
    if (!selectedExposure) {
      return;
    }

    const activeAndBelowLimit =
      findActiveAndBelowLimitFromExposure(selectedExposure);
    const updatedCategory = {
      ...category,
      ...activeAndBelowLimit,
      exposureLevelNotes: exposureLevelNotes ? exposureLevelNotes.trim() : '',
    };

    authContext.authenticatedFetchWithToast('/api/Answers/category', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedCategory),
    });
    setPrevExposure(selectedExposure);
    setPrevExposureLevelNotes(exposureLevelNotes);
    responseContext.refreshCategory(updatedCategory);
  };

  if (!category) {
    return <h1>Error</h1>;
  }

  return (
    <>
      <div className={styles.heading}>
        <h1>{t('Exposure').toUpperCase()}</h1>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.description}>
          <h1>{t('Description')}</h1>
          {category.exposure}
        </div>
        {!category.alwaysActive && (
          <>
            <ExposureSelector
              category={category}
              onChange={handleSelectedExposureChange}
            />
            <ExposureLevelNotes
              exposureLevelNotes={exposureLevelNotes}
              onChange={handleExposureLevelNotesChange}
            />
            <button
              className={styles.saveButton}
              onClick={() => saveCategory()}
              disabled={
                !responseContext.response?.active ||
                (selectedExposure == prevExposure &&
                  exposureLevelNotes?.trim() == prevExposureLevelNotes)
              }
            >
              {t('Save')}
            </button>
          </>
        )}
      </div>
    </>
  );
};

const ExposureSelector = ({
  category,
  onChange,
}: {
  category: ICategoryResponseDto;
  onChange: (newSelectedExposure: string) => void;
}) => {
  const { t } = useTranslation();
  const responseContext = useContext(ResponseContext);

  return (
    <div className={styles.exposure}>
      <h1>
        {category.exposureLevelTitle !== ''
          ? category.exposureLevelTitle
          : t('Choose single for company')}
      </h1>
      <ul>
        {category.hasLimit ? (
          <>
            <li className={styles.radioButtonContainer}>
              <input
                type="radio"
                name="exposure"
                id="highExposure"
                className={styles.radioButton}
                defaultChecked={category.active && !category.belowLimit}
                value={ExposureLevel.HIGH}
                onChange={(e) => onChange(e.currentTarget.value)}
                disabled={!responseContext.response?.active}
              />
              <label htmlFor="highExposure">{t('High exposure')}</label>
            </li>
            <li className={styles.radioButtonContainer}>
              <input
                type="radio"
                name="exposure"
                id="mediumExposure"
                className={styles.radioButton}
                defaultChecked={category.active && category.belowLimit}
                value={ExposureLevel.MEDIUM}
                onChange={(e) => onChange(e.currentTarget.value)}
                disabled={!responseContext.response?.active}
              />
              <label htmlFor="mediumExposure">{t('Medium exposure')}</label>
            </li>
            <li className={styles.radioButtonContainer}>
              <input
                type="radio"
                name="exposure"
                id="lowExposure"
                className={styles.radioButton}
                defaultChecked={!category.active}
                value={ExposureLevel.LOW}
                onChange={(e) => onChange(e.currentTarget.value)}
                disabled={!responseContext.response?.active}
              />
              <label htmlFor="lowExposure">{t('Low exposure')}</label>
            </li>
          </>
        ) : (
          <>
            <li className={styles.radioButtonContainer}>
              <input
                type="radio"
                name="exposure"
                id="highExposure"
                className={styles.radioButton}
                defaultChecked={category.active}
                value={ExposureLevel.HIGH}
                onChange={(e) => onChange(e.currentTarget.value)}
                disabled={!responseContext.response?.active}
              />
              <label htmlFor="highExposure">{t('Yes')}</label>
            </li>
            <li className={styles.radioButtonContainer}>
              <input
                type="radio"
                name="exposure"
                id="lowExposure"
                className={styles.radioButton}
                defaultChecked={!category.active}
                value={ExposureLevel.LOW}
                onChange={(e) => onChange(e.currentTarget.value)}
                disabled={!responseContext.response?.active}
              />
              <label htmlFor="lowExposure">{t('No')}</label>
            </li>
          </>
        )}
      </ul>

      {category.hasLimit && (
        <div>{t("Choose high exposure if you are unsure or don't know")}</div>
      )}
    </div>
  );
};

export default CategoryExposure;
