import React, { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext, authenticatedFetch } from "../../components/AuthenticationContext";
import { useTranslation } from 'react-i18next';
import Styles from './UserAdministration.module.scss';
import { combineClasses } from '../../utils/Utils';
import { NaceAdministration } from './NaceAdministration';
import { Multiselect } from './Multiselect';
import { ICompanyDto, INaceDto } from '../../generated/ApiTypes';

export const CompanyAdministration = () => {
  const { t } = useTranslation();
  const [companies, setCompanies] = useState<ICompanyDto[]>();
  const [naces, setNaces] = useState<INaceDto[]>([]);
  const refresh = useCallback(() => {
    authenticatedFetch('api/companies')
      .then((r) => r.json())
      .then(setCompanies);
    authenticatedFetch('api/companies/nace')
      .then((r) => r.json())
      .then(setNaces);
  }, []);
  useEffect(refresh, []);
  return (
    <>
      <NaceAdministration />

      <div className={combineClasses(Styles.AdminPage, Styles.WithBackground)}>
        <div className={Styles.row}>
          <h1>{t('Company Administration')}</h1>
          <button onClick={refresh} className="btn blue">
            {t('Refresh')}
          </button>
        </div>
        <div className={Styles.UserCardsContainer}>
          {companies?.map((c) => (
            <CompanyCard
              key={c.companyId}
              company={c}
              naces={naces}
              refresh={refresh}
            />
          )) ?? t('Loading companies')}
          <CompanyCard naces={naces} refresh={refresh} />
        </div>
      </div>
    </>
  );
};

const useString = (masterString?: string) => {
  const state = useState(masterString);
  useEffect(() => state[1](masterString), [masterString]);
  return state;
};

const CompanyCard = (props: {
  company?: ICompanyDto;
  naces?: INaceDto[];
  refresh?: () => void;
}) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const [name, setName] = useString(props.company?.name ?? '');
  const [selectedOptions, setSelectedOptions] = useState(
    props.company?.naces ?? []
  );

  const handleUpdate = async () => {
    await authContext.authenticatedFetchWithToast(`api/companies/${props.company?.companyId}`, {
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name: name,
        naces: selectedOptions,
      }),
    });
    props.refresh?.();
  };

  return (
    <div className={Styles.UserCard}>
      {props.company ? (
        <span className={Styles.UserAttribute}>{name ?? ':/'}</span>
      ) : (
        <input
          className={Styles.UserAttribute}
          placeholder={t('Name')}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      )}
      {props.naces && (
        <Multiselect
          options={props.naces}
          selectedOptions={selectedOptions}
          onSelectedOptionsChange={setSelectedOptions}
        />
      )}
      {props.company ? (
        <>
          <button className="btn blue" onClick={handleUpdate}>
            {t('Refresh')}
          </button>
          <button
            className="btn blue"
            onClick={async () => {
              if (
                confirm(
                  t('Confirm delete', {
                    type: t('Company').toLowerCase(),
                    name: props.company?.name,
                  })
                )
              ) {
                await authContext.authenticatedFetchWithToast(
                  `api/companies/${props.company?.companyId}`,
                  {
                    method: 'delete',
                  }
                );
                props.refresh?.();
              }
            }}
          >
            {t('Delete company')}
          </button>

          <div className={Styles.NaceCodeList}>
            {props.company.naces
              ?.sort((a, b) => (a.code > b.code ? 1 : -1))
              .map((nace) => (
                <div
                  key={`nace${nace.naceId}`}
                  className={Styles.NaceCode}
                >{`${nace.code} - ${nace.description}`}</div>
              ))}
          </div>
        </>
      ) : (
        <button
          className="btn blue"
          onClick={async () => {
            await authContext.authenticatedFetchWithToast('api/companies', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                name,
                naces: selectedOptions,
              }),
            });
            props.refresh?.();
            setName('');
            setSelectedOptions([]);
          }}
        >
          {t('Add company')}
        </button>
      )}
    </div>
  );
};
