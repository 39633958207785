import React, { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import Styles from './Toaster.module.scss';

interface Toast {
  key: number;
  type: ToastType;
  content: ReactNode;
}

type ToastType = 'waiting' | 'success' | 'error'

interface Props {
  toasts: Toast[],
  removeToast: (key: number) => void;
}

export const Toaster = (props: Props) => {
  return (
    <div className={Styles['toast-container']}>
      {props.toasts.map(toast =>
        <Toast key={toast.key} type={toast.type} close={() => props.removeToast(toast.key)}>{toast.content}</Toast>,
      )}
    </div>
  );
};

interface ToastProps {
  type: ToastType;
  close: () => void;
}

export const Toast: React.FC<ToastProps> = (props) => {
  useEffect(() => {
    if (props.type === 'waiting' || props.type === 'success')
      setTimeout(props.close, 5000);
  }, []);
  return <div className={`${Styles.toast} ${Styles[props.type]}`}>
    <span className={Styles.message}>{props.children}</span>
    <button className={Styles['close-button']} onClick={props.close}>x</button>
  </div>;
};

export const ToastContext = createContext<{ toasts: Toast[], addToast: AddToast }>({
  toasts: [],
  addToast: () => {
  },
});

type AddToast = (params: { type: ToastType, message: string }) => void;

const generateNewToastKey = () => Date.now();

export const ToastProvider: React.FC = (props) => {
  const [toasts, setToasts] = useState<Toast[]>([]);
  const addToast = useCallback<AddToast>((params) => {
    setToasts(prevState => [...prevState, {
      key: generateNewToastKey(),
      type: params.type,
      content: params.message,
    }]);
  }, [setToasts]);
  const removeToast = useCallback((key: number) => {
    setToasts(prevState => {
      return prevState.filter(t => t.key !== key);
    });
  }, []);
  return <ToastContext.Provider value={{ toasts, addToast }}>
    {props.children}
    <Toaster toasts={toasts} removeToast={removeToast} />
  </ToastContext.Provider>;
};
