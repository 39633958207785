import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Layout.module.scss';
import { AuthContext } from '../AuthenticationContext';
import { useTranslation } from 'react-i18next';
import { SelfContext } from '../SelfContext';
import { Roles } from '../../Constants';

const Layout: React.FC = (props) => {
  const authContext = useContext(AuthContext);
  const { t, i18n } = useTranslation();
  const { role, companyId, companyName, refresh, departments } =
    useContext(SelfContext);
  const [accessToCompany, setAccessToCompany] = useState(
    (role == Roles.ADMIN || role == Roles.OWNER) &&
      companyId != null &&
      companyId != ''
  );
  useEffect(() => {
    setAccessToCompany(
      (role == Roles.ADMIN || role == Roles.OWNER) &&
        companyId != null &&
        companyId != ''
    );
  }, [role, companyId]);
  return (
    <>
      <div className={styles.navbar}>
        <NavLink to="/" className={styles.logo} onClick={refresh}>
          <img src="logo.png" alt="Logo" />
        </NavLink>
        <ul className={styles.navLinks}>
          <li className={styles.navItem}>
            <NavLink to="/" onClick={refresh}>
              {t('E-Scores')}
            </NavLink>
          </li>
          {(accessToCompany ||
            departments.some((d) => d.role == Roles.OWNER)) && (
            <>
              <li className={styles.navItem}>
                <NavLink to="/departments">
                  {t('Administer departments')}
                </NavLink>
              </li>
              <li className={styles.navItem}>
                <NavLink to="/company/users">
                  {t('Administer company users')}
                </NavLink>
              </li>
              {/* TODO: REMOVE: */}
              <li className={styles.navItem}>
                <NavLink to="/responses">{t('Administer responses')}</NavLink>
              </li>
            </>
          )}
          {role == Roles.ADMIN && (
            <>
              <li className={styles.navItem}>
                <NavLink to="/users">{t('Administer users')}</NavLink>
              </li>
              <li className={styles.navItem}>
                <NavLink to="/companies">{t('Administer companies')}</NavLink>
              </li>
              <li className={styles.navItem}>
                <NavLink to="/schemas">{t('Administer forms')}</NavLink>
              </li>
            </>
          )}
        </ul>
        <div className={styles.right}>
          <i className="fas fa-globe-europe fa-2x"></i>
          <select
            onChange={(e) => i18n.changeLanguage(e.target.value)}
            value={i18n.language}
            className={styles.select}
          >
            <option value="nb-NO">{t('Norwegian')}</option>
            <option value="en-US">{t('English')}</option>
          </select>
          {authContext.isAuthenticated && (
            <button onClick={authContext.logout}>{t('Log out')}</button>
          )}
        </div>
      </div>
      {props.children}
    </>
  );
};

export default Layout;
