import React, { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext, authenticatedFetch } from "../../components/AuthenticationContext";
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import Styles from './UserAdministration.module.scss';
import { IDepartmentDto, IUserDto } from '../../generated/ApiTypes';
import { combineClasses } from '../../utils/Utils';
import { Roles } from '../../Constants';

export const UserAdministration = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState<IUserDto[]>();
  const [companies, setCompanies] =
    useState<Array<{ value: string; label: string }>>();
  const refresh = useCallback(() => {
    authenticatedFetch('api/users', {})
      .then((r) => r.json())
      .then((users: IUserDto[]) => setUsers(users.filter((u) => u.mail)));
    authenticatedFetch('api/companies')
      .then((r) => r.json())
      .then((newCompanies: Array<{ companyId: string; name: string }>) =>
        setCompanies(
          newCompanies.map((c) => ({ value: c.companyId, label: c.name }))
        )
      );
  }, []);
  useEffect(refresh, []);
  return (
    <div className={combineClasses(Styles.AdminPage, Styles.WithBackground)}>
      <div className={Styles.row}>
        <h1>{t('User Administration')}</h1>
        <button onClick={refresh} className="btn blue">
          {t('Refresh')}
        </button>
      </div>
      <div className={Styles.UserCardsContainer}>
        {users?.map((u) => (
          <UserCard
            key={u.id}
            user={u}
            companies={companies}
            refresh={refresh}
          />
        )) ?? t('Loading users')}
        <UserCard companies={companies} refresh={refresh} />
      </div>
    </div>
  );
};

const useString = (masterString?: string) => {
  const state = useState(masterString);
  useEffect(() => state[1](masterString), [masterString]);
  return state;
};

const UserCard = (props: {
  user?: IUserDto;
  companies?: Array<{ value: string; label: string }>;
  refresh?: () => void;
}) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const [displayName, setDisplayName] = useString(
    props.user?.displayName ?? ''
  );
  const [mail, setMail] = useString(props.user?.mail ?? '');
  const [companyId, setCompanyId] = useString(
    props.companies?.find((c) => c.value === props.user?.companyId)
      ? props.user?.companyId
      : undefined
  );
  const [role, setRole] = useString(props.user?.role);
  const roleOptions = [
    { value: Roles.ADMIN, label: t('Admin') },
    { value: Roles.OWNER, label: t('Company owner') },
    { value: Roles.READER, label: t('Company read access') },
  ];

  return (
    <div className={Styles.UserCard}>
      <label className={Styles.UserAttribute}>
        {t('Name')}
        {props.user ? (
          <span>{displayName ?? ':/'}</span>
        ) : (
          <input
            placeholder={t('Name')}
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
          />
        )}
      </label>
      <label className={Styles.UserAttribute}>
        {t('E-mail')}
        {props.user ? (
          <span>{mail ?? ':/'}</span>
        ) : (
          <input
            placeholder={t('E-mail')}
            value={mail}
            onChange={(e) => setMail(e.target.value)}
          />
        )}
      </label>
      <label className={Styles.UserAttribute}>
        {t('Company')}
        <Select
          isClearable={true}
          value={props.companies?.find((c) => c.value === companyId)}
          options={props.companies ?? [{ value: '', label: '' }]}
          placeholder={t('Select')}
          onChange={
            props.user
              ? (selected?: any) =>
                  authContext.authenticatedFetchWithToast(`api/users/${props.user?.id}`, {
                    method: 'PUT',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      companyId: selected?.value,
                      departments: [],
                      role: role,
                    }),
                  })
                    .then((r) => r.json())
                    .then((r) => {
                      setCompanyId(r.companyId);
                    })
              : (selected: any) => {
                  setCompanyId(selected?.value);
                }
          }
        />
      </label>
      <label className={Styles.UserAttribute}>
        {t('Role')}
        <Select
          isClearable={true}
          value={roleOptions.find((r) => r.value == role) ?? undefined}
          options={roleOptions}
          placeholder={t('Select')}
          onChange={
            props.user
              ? (selected: any) =>
                  authContext.authenticatedFetchWithToast(`api/users/${props.user?.id}`, {
                    method: 'PUT',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      role: selected?.value,
                      companyId: companyId,
                      departments: props.user?.departments,
                    }),
                  })
                    .then((r) => r.json())
                    .then((r) => setRole(r.role))
              : (selected: any) => setRole(selected?.value)
          }
        />
      </label>
      {props.user ? (
        <button
          className="btn blue"
          onClick={async () => {
            if (
              confirm(
                t('Confirm delete', {
                  type: t('User').toLowerCase(),
                  name: props.user?.displayName,
                })
              )
            ) {
              await authContext.authenticatedFetchWithToast(`api/users/${props.user?.id}`, {
                method: 'delete',
              });
              props.refresh?.();
            }
          }}
        >
          {t('Delete user')}
        </button>
      ) : (
        <button
          className="btn blue"
          disabled={displayName == '' || mail == ''}
          onClick={async () => {
            await authContext.authenticatedFetchWithToast('api/users', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                displayName,
                mail,
                companyId,
                role,
              }),
            });
            props.refresh?.();
            setDisplayName('');
            setMail('');
          }}
        >
          {t('Add user')}
        </button>
      )}
    </div>
  );
};
