import React, { FC } from 'react';
import styles from './EvalueMeter.module.scss';

interface Props {
  value: number;
  hideText?: boolean;
}

const getClipSizeColor = (value: number) => {
  if (value >= 100) return '100%';
  if (value >= 95) return '95%';
  if (value >= 90) return '90%';
  if (value >= 80) return '80%';
  if (value >= 60) return '60%';
  if (value >= 50) return '50%';
  if (value >= 40) return '40%';
  if (value >= 30) return '30%';
  if (value >= 20) return '20%';
  if (value >= 15) return '15%';
  if (value >= 10) return '10%';
  if (value >= 5) return '5%';
  return '4%';
};

export const EvalueMeter: FC<Props> = (props) => {
  const size = getClipSizeColor(props.value);
  const meterStyles = {
    width: `10rem`,
    '--size': size,
  };

  return (
    <div className={styles.meterBox}>
      <div className={styles.metercontainer}>
        <div className={styles.meter} style={meterStyles}></div>
      </div>
      <div className={styles.metertext}>
        {' '}
        {!props.hideText && props.value > 0 && props.value.toFixed(1)}
        {!props.hideText && props.value > 0 && '%'}
      </div>
    </div>
  );
};
