import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Panel } from 'primereact/panel';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import styles from './Home.module.scss';
import {
  AuthContext,
  authenticatedFetch,
} from '../../components/AuthenticationContext';
import { useTranslation } from 'react-i18next';
import { SelfContext } from '../../components/SelfContext';
import { Link } from 'react-router-dom';
import { HomeTabs, Languages, Roles } from '../../Constants';
import {
  IDepartmentEvalueDto,
  IDepartmentResponseDto,
  IUserDepartmentDto,
} from '../../generated/ApiTypes';
import { EvalueMeter } from '../../components/EvalueMeter/EvalueMeter';
import { combineClasses } from '../../utils/Utils';
import Spinner from '../../components/Spinner/Spinner';

const Home = () => {
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<HomeTabs>();

  const background = authContext.isAuthenticated
    ? styles.backgroundwhitewrapper
    : styles.backgroundimagewrapper;
  const classes = styles.wrapper + ' ' + background;

  return (
    <div className={classes}>
      <div className={styles.box}>
        {authContext.isAuthenticated && (
          <>
            <AccessTabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            {selectedTab == HomeTabs.EDIT && <HomeEdit />}
            {selectedTab == HomeTabs.NOACCESS && (
              <div className={styles.content}>
                {t('No department accesses')}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const HomeEdit = () => {
  /*
  Commented out code gives the opportunity to filter schemas based on language
  */
  const companyContext = useContext(SelfContext);
  const [activeResponses, setActiveResponses] = useState<
    IDepartmentResponseDto[]
  >(companyContext.departmentResponses?.filter((r) => r.active) ?? []);
  const [archivedResponses, setArchivedResponses] = useState<
    IDepartmentResponseDto[]
  >(companyContext.departmentResponses?.filter((r) => !r.active) ?? []);
  const [writerDepartments, setWriterDepartments] = useState<
    IUserDepartmentDto[]
  >(companyContext.departments.filter((d) => d.role == Roles.WRITER) ?? []);
  const [selectedSchemaId, setSelectedSchemaId] = useState<number>();
  const [selectedDepartmentId, setSelectedDepartmentId] = useState<number>();
  const [displayArchived, setDisplayArchived] = useState<boolean>(false);
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);

  const handleSchemaChange = (event: any) => {
    setSelectedSchemaId(parseInt(event.target.value));
  };

  const archiveResponse = (response: IDepartmentResponseDto) => {
    const inputText = prompt(
      t('Prompt archive yes', {
        type: t('Response').toLowerCase(),
        name: response.name,
      }),
    )?.trim();
    if (inputText === 'yes') {
      authContext.authenticatedFetchWithToast(`/api/response/${response.id}/active`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          active: false,
        }),
      }).then(() => {
        companyContext.updateDepartmentResponseActive(response.id, false);
        setActiveResponses(activeResponses.filter((r) => r.id != response.id));
        setArchivedResponses(archivedResponses.concat([response]));
      });
    }
  };

  const deleteResponse = (responseId: number, name: string) => {
    const inputText = prompt(
      t('Prompt delete yes', { type: t('Response').toLowerCase(), name }),
    )?.trim();
    if (inputText === 'yes') {
      authContext.authenticatedFetchWithToast('/api/response/' + responseId, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(() => {
        companyContext.refresh();
      });
    }
  };

  const getPanelheader = (d: IUserDepartmentDto) => {
    if (d.parentPath != null) {
      return `${d.parentPath}  ${d.departmentName}`;
    } else {
      return `${d.departmentName}`;
    }
  };

  useEffect(() => {
    setSelectedSchemaId(companyContext.schemas[0]?.schemaId);
    setSelectedDepartmentId(parseInt(writerDepartments[0]?.departmentId));
  }, []);

  useEffect(() => {
    setActiveResponses(
      companyContext.departmentResponses?.filter((r) => r.active) ?? [],
    );
    setArchivedResponses(
      companyContext.departmentResponses?.filter((r) => !r.active) ?? [],
    );
    setWriterDepartments(
      companyContext.departments.filter((d) => d.role == Roles.WRITER) ?? [],
    );
  }, [
    companyContext,
    companyContext.departmentResponses,
    companyContext.departments,
  ]);

  return (
    <div className={styles.content}>
      <div className={styles.headerresponses}>
        <h3> Oversikt besvarelser</h3>
      </div>
      {writerDepartments.map((d) => (
        <div key={d.id}>
          <Panel header={getPanelheader(d)} toggleable>
            <ul className={styles.list}>
              {activeResponses
                .filter((r) => r.departmentId.toString() == d.departmentId)
                .map((r) => (
                  <li key={r.id}>
                    <b className={styles.responsename}>{r.name}</b>
                    <div className={styles.responseLineContent}>
                      <span className={styles.textForm}>{t('E-Scores')}:</span>
                      <div className={styles.evalueMeterContainer}>
                        <EvalueMeter value={r.evalue} />
                      </div>
                      <Link to={`response/${r.id}`}>
                        <button
                          className={combineClasses(
                            styles.delete,
                            styles.deleteResponse,
                          )}
                        >
                          Se besvarelse
                        </button>
                      </Link>
                      <button
                        className={combineClasses(
                          styles.deleteArchive,
                          styles.deleteResponse,
                        )}
                        onClick={() => archiveResponse(r)}
                      >
                        {t('Archive')}
                      </button>
                    </div>
                  </li>
                ))}
            </ul>
          </Panel>
        </div>
      ))}

      <div className={styles.separator}>
        {t('Add new')} {t('Response').toLowerCase()}
      </div>

      <form
        className={styles.newResponse}
        onSubmit={(e) => {
          e.preventDefault();
          // @ts-ignore
          const textInput = e.target.querySelector('input[name="name"]');
          const name = textInput.value;
          if (name != '') {
            const schemaId =
              selectedSchemaId ?? companyContext.schemas[0]?.schemaId;
            const departmentId =
              selectedDepartmentId ??
              parseInt(writerDepartments[0]?.departmentId);
            authContext.authenticatedFetchWithToast('/api/response', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                name: name,
                schemaId: schemaId,
                departmentId: departmentId,
              }),
            }).then(() => {
              companyContext.refresh();
              textInput.value = '';
            });
          }
        }}
      >
        <input type='text' name='name' placeholder={t('Name')} />

        <select
          className={styles.select}
          onChange={(e) => setSelectedDepartmentId(parseInt(e.target.value))}
        >
          {writerDepartments.map((dep) => {
            return (
              <option
                key={`version_${dep.departmentId}`}
                value={dep.departmentId}
              >
                {dep.departmentName}
              </option>
            );
          })}
        </select>

        <select
          className={styles.select}
          onChange={(e) => handleSchemaChange(e)}
        >
          {companyContext.schemas.map((schema) => {
            return (
              <option
                key={`version_${schema.schemaId}`}
                value={schema.schemaId}
              >
                {schema.name} ({schema.language})
              </option>
            );
          })}
        </select>
        <button className={styles.add}>{t('Add')}</button>
      </form>

      <div
        className={styles.separator}
        onClick={() => setDisplayArchived(!displayArchived)}
      >
        {t('Archived responses')}
        <i
          className={combineClasses(
            displayArchived ? 'fas fa-caret-up' : 'fas fa-caret-down',
            styles.icon,
          )}
        />
      </div>

      {displayArchived &&
        writerDepartments.map((d) => (
          <div key={d.id}>
            <h1>{d.departmentName}</h1>
            <ul className={styles.list}>
              {archivedResponses
                .filter((r) => r.departmentId.toString() == d.departmentId)
                .map((r) => (
                  <li key={r.id}>
                    <div>
                      <Link to={`response/${r.id}`}>
                        <div className={styles.archivedname}> {r.name} </div>
                        <span className={styles.schemaName}>
                          {r.schemaName} - {r.language}
                        </span>
                      </Link>
                    </div>
                    <div className={styles.actions}>
                      <button
                        className={styles.delete}
                        onClick={() => deleteResponse(r.id, r.name)}
                      >
                        {t('Delete')}
                      </button>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        ))}
    </div>
  );
};

const AccessTabs = (props: {
  selectedTab?: HomeTabs;
  setSelectedTab: (selectedTab: HomeTabs) => void;
}) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const { companyId, departments, role, companyName } = useContext(SelfContext);
  const [writeAccess, setWriteAccess] = useState(false);
  const [readAccess, setReadAccess] = useState(false);

  useEffect(() => {
    const write = departments.some((d) => d.role == Roles.WRITER);
    const companyAccess =
      (role == Roles.ADMIN || role == Roles.OWNER || role == Roles.READER) &&
      !(companyId == null || companyId == '');
    const read =
      companyAccess ||
      departments.some((d) => d.role == Roles.READER || d.role == Roles.OWNER);
    if (write) {
      props.setSelectedTab(HomeTabs.EDIT);
    } else if (read) {
      props.setSelectedTab(HomeTabs.READ);
    } else {
      props.setSelectedTab(HomeTabs.NOACCESS);
    }
    setWriteAccess(write);
    setReadAccess(read);
  }, [departments, companyId, role]);

  return (
    <div className={styles.tabs}>
      <div className={styles.companytitle}>{companyName}</div>
      <Link to={`dash/company/${companyId}`}>
        <button className={styles.showcompany}>Se virksomhet</button>
      </Link>
    </div>
  );
};

export default Home;
